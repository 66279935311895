import { ErrorMessage, Field } from "formik";
import React from "react";

const getFieldCSSClasses = (touched, errors) => {
  const classes = ["form-control"];
  if (touched && errors) {
    classes.push("is-invalid");
  }

  if (touched && !errors) {
    classes.push("is-valid");
  }

  return classes.join(" ");
};

export function InputWithLimit({ ...props }) {
  return (
    <>
      {props.value && props.displaylimit ? (
        <div className="row">
          <div className="col-6">
            <label htmlFor={props.name}>{props.label}</label>
          </div>
          <div className="col-6 text-right">
            <small className="text-muted">
              ({props.maxLength - props.value.length}/{props.maxLength})
            </small>
          </div>
        </div>
      ) : (
        <label htmlFor={props.name}>{props.label}</label>
      )}

      <Field
        name={props.name}
        type={props.type}
        className={getFieldCSSClasses(props.touched, props.errors)}
        maxLength={props.maxLength}
        {...props}
      />

      <ErrorMessage
        name={props.name}
        component="div"
        className="invalid-feedback"
      />
    </>
  );
}
