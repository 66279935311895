import PropTypes from "prop-types";
import React, { Fragment } from "react";
import { ScrollRestoration } from "react-router-dom";
import FooterOne from "../Footers/FooterOne";
import HeaderTwo from "../Headers/HeaderTwo";

const LayoutTwo = ({ children }) => {
  return (
    <Fragment>
      <HeaderTwo />
      {children}
      <FooterOne spaceTopClass="pt-50" spaceBottomClass="pb-40" />
      <ScrollRestoration />
    </Fragment>
  );
};

LayoutTwo.propTypes = {
  children: PropTypes.any,
};

export default LayoutTwo;
