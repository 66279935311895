import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import LayoutTwo from "../../Components/Layout/LayoutTwo";
import AboutBody from "./Widgets/AboutBody.js";

const AboutPage = () => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>About | Stripe Competition</title>
        <meta
          name="description"
          content="Coding, Robotics, and Technology Competition."
        />
      </Helmet>
      <LayoutTwo>
        <AboutBody></AboutBody>
      </LayoutTwo>
    </HelmetProvider>
  );
};

export default AboutPage;
