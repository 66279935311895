import React, { useState } from "react";
import { Button, Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import InnovationImage from "../assets/img/innovation-background-img.png";
import ProgrammingImage from "../assets/img/programming-background-img.png";
import RoboticsImage from "../assets/img/robotics-background-img.png";
import { ReactComponent as Programming } from "../assets/img/svg/programming.svg";
import { ReactComponent as Robotics } from "../assets/img/svg/robotics.svg";
import { ReactComponent as Technology } from "../assets/img/svg/technology.svg";
import ArrowWidget from "./ArrowHeader";

const AgeCategoriesTable = () => {
  return (
    <>
      <div className="row">
        <div className="col--12 text-center text-bolder text-uppercase">
          <h4>Age Categories</h4>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-4 col-sm-12 text-center text-bolder text-uppercase">
          <img
            src={process.env.PUBLIC_URL + "/assets/covers/nebula.png"}
            alt="nebula"
            className="season-age-icon"
          />
        </div>
        <div className="col-lg-4 col-sm-12 text-center text-bolder text-uppercase">
          Nebula
        </div>
        <div className="col-lg-4 col-sm-12 text-center">AGES 6-9</div>
      </div>
      <div className="row">
        <div className="col-lg-4 col-sm-12 text-center text-bolder text-uppercase">
          <img
            src={process.env.PUBLIC_URL + "/assets/covers/star.png"}
            alt="star"
            className="season-age-icon"
          />
        </div>
        <div className="col-lg-4 col-sm-12 text-center text-bolder text-uppercase">
          Star
        </div>
        <div className="col-lg-4 col-sm-12 text-center">AGES 9-13</div>
      </div>
      <div className="row">
        <div className="col-lg-4 col-sm-12 text-center text-bolder text-uppercase">
          <img
            src={process.env.PUBLIC_URL + "/assets/covers/supernova.png"}
            alt="supernova"
            className="season-age-icon"
          />
        </div>
        <div className="col-lg-4 col-sm-12 text-center text-bolder text-uppercase">
          Super Nova
        </div>
        <div className="col-lg-4 col-sm-12 text-center">AGES 12-15</div>
        <div className="col-lg-4 col-sm-12 text-center text-bolder text-uppercase">
          <img
            src={process.env.PUBLIC_URL + "/assets/covers/galaxy.png"}
            alt="supernova"
            className="season-age-icon"
          />
        </div>
        <div className="col-lg-4 col-sm-12 text-center text-bolder text-uppercase">
          Galaxy
        </div>
        <div className="col-lg-4 col-sm-12 text-center">AGES 15-20</div>
      </div>
    </>
  );
};

const RoboticsSection = () => {
  const navigate = useNavigate();
  return (
    <Card className="mb-10">
      <Card.Header className="bg-primary">
        <Card.Title>
          <h3 className="text-light text-bolder text-uppercase">Robotics</h3>
        </Card.Title>
      </Card.Header>
      <Card.Body>
        <div className="row">
          <div className="col-lg-4 text-center pb-10">
            <img
              className="rounded"
              width={"50%"}
              src={RoboticsImage}
              alt="Robotics"
            />
          </div>

          <div className="col-lg-4 pb-10">
            {/* Add Text Here */}

            <p>
              <b>Team Size:</b> 2 - 3 members
              <br />
              <b>Hardware:</b> Lego WeDo 2.0, NXT, EV3, Spike Prime
            </p>
            <p>
              Explore the Robotics category, where engineering meets creativity!
              Design and program robots to tackle thrilling challenges in
              collaboration with your team.
            </p>
          </div>

          <div className="col-lg-4 pb-10">
            <AgeCategoriesTable />
          </div>
        </div>
      </Card.Body>
      <Card.Footer className="bg-primary text-center">
        <Button
          className="btn btn-secondary text-bold"
          onClick={() => {
            navigate("/events?streamId=2");
          }}
        >
          Find Events
        </Button>
      </Card.Footer>
    </Card>
  );
};

const InnovationSection = () => {
  const navigate = useNavigate();
  return (
    <Card className="mb-10">
      <Card.Header className="bg-tertiary">
        <Card.Title>
          <h3 className="text-light text-bolder text-uppercase">Innovation</h3>
        </Card.Title>
      </Card.Header>
      <Card.Body>
        <div className="row">
          <div className="col-lg-4 text-center pb-10">
            <img
              className="rounded"
              width={"50%"}
              src={InnovationImage}
              alt="Innovation"
            />
          </div>
          <div className="col-lg-4 pb-10">
            {/* Add Text Here */}{" "}
            <p>
              <b>Team Size:</b> 2 - 3 members
              <br />
              <b>Hardware:</b> All Hardware allowed
            </p>
            <p>
              Enter the <strong>Innovation</strong> category, where creativity
              meets real-world solutions! Present your groundbreaking ideas and
              prototypes that tackle current challenges. Join us to inspire and
              be inspired as we explore the future of innovation together!
            </p>
          </div>
          <div className="col-lg-4 pb-10">
            <AgeCategoriesTable />
          </div>
        </div>
      </Card.Body>
      <Card.Footer className="bg-tertiary text-center">
        <Button
          className="btn btn-secondary text-bold"
          onClick={() => {
            navigate("/events?streamId=3");
          }}
        >
          Find Events
        </Button>
      </Card.Footer>
    </Card>
  );
};

const ProgrammingSection = () => {
  const navigate = useNavigate();
  return (
    <Card className="mb-10">
      <Card.Header className="bg-secondary">
        <Card.Title>
          <h3 className="text-light text-bolder text-uppercase">Programming</h3>
        </Card.Title>
      </Card.Header>
      <Card.Body>
        <div className="row">
          <div className="col-lg-4 text-center pb-10">
            <img
              className="rounded"
              width={"50%"}
              src={ProgrammingImage}
              alt="Programming"
            />
          </div>

          <div className="col-lg-4 pb-10">
            {/* Add Text Here */}

            <p>
              <b>Team Size:</b> 2 - 3 members
              <br />
              <b>Programming Languages:</b> Event dependant
            </p>
            <p>
              Unleash your coding skills in the Programming category! Take on
              challenges that span from algorithm puzzles to app development and
              elevate your programming expertise to new heights!
            </p>
          </div>
          <div className="col-lg-4 pb-10">
            <AgeCategoriesTable />
          </div>
        </div>
      </Card.Body>
      <Card.Footer className="bg-secondary text-center">
        <Button
          className="btn btn-primary text-bold"
          onClick={() => {
            navigate("/events?streamId=1");
          }}
        >
          Find Events
        </Button>
      </Card.Footer>
    </Card>
  );
};

const ServiceCat = [
  {
    class: "robotics",
    category: "Robotics",
    icon: Robotics,
    section: RoboticsSection,
  },
  {
    class: "innovation",
    category: "Innovation",
    icon: Technology,
    section: InnovationSection,
  },
  {
    class: "programming",
    category: "Programming",
    icon: Programming,
    section: ProgrammingSection,
  },
];

const StreamsWithDetails = () => {
  const [streamSelected, setStreamSelected] = useState({});

  return (
    <div id="streams" className="mt-40 mb-40">
      <div className="row">
        <div className="col-12 text-center">
          <ArrowWidget title={"Streams"} />
        </div>
      </div>
      <div className="row mt-10 mb-20">
        <div className="offset-2 col-8 text-center">
          {/* Add Text Here */}
          <p className=" section-text">
            Every year, STRIPE introduces two series of challenges for Fall and
            Spring in the categories of robotics and programming. Form a team,
            build robots, code games, and collaborate with peers across various
            skill levels from the U.S., Canada, and Jamaica to climb to the top
            of the leaderboard!
          </p>
        </div>
      </div>
      <div className="row mb-10">
        {ServiceCat.map((value, index) => (
          <div className="col-lg-4 col-md-4 col-sm-12" key={index}>
            <div
              className={`hover-box ${ServiceCat[index].class} ${
                streamSelected.class === ServiceCat[index].class &&
                "active-hover-box"
              } text-center position-relative`}
              onClick={() => {
                if (
                  Object.keys(streamSelected).length > 0 &&
                  streamSelected.class === ServiceCat[index].class
                ) {
                  setStreamSelected({});
                } else {
                  setStreamSelected(ServiceCat[index]);
                }
              }}
            >
              <div
                className={`${value.class + "-hover-img"} position-absolute`}
              ></div>
              <div className="hover-box-inner">
                <value.icon />
                <h4 className="text-capitalize mb-0 mt-1">{value.category}</h4>
              </div>
            </div>
          </div>
        ))}
      </div>
      {Object.keys(streamSelected).length > 0 && <streamSelected.section />}
    </div>
  );
};
export default StreamsWithDetails;
