/**
 * Sign Up Area
 * @desc The contents of the signup page
 * @component
 * @public
 *
 * @param None
 *
 * @todo
 * - Add https://www.npmjs.com/package/react-country-state-city
 * - Fix form address selects
 *
 * @example
 * Note: Remember to wrap in a Layout
 *       component
 * <SignupArea />
 *
 * @author Asad Siddiqui
 * @created_on 2024-09-05
 * @modified_on 2024-09-07
 */
import axios from "axios";
import csc from "country-state-city";
import { Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { usStates } from "../../../helpers/country";
import {
  getFormattedNumber,
  getVanillaPhoneNumber,
  trimOffTheTop,
} from "../../../helpers/formatter";
import { authActions } from "../../../store/auth/authSlice";

const setTestValues = (
  firstName,
  address1,
  address2,
  city,
  zip,
  country,
  state,
  email,
  setFieldValue,
  setFieldTouched
) => {
  //ParentForm
  setFieldValue("firstname", firstName);
  setFieldTouched("firstname", true);
  setFieldValue("lastname", firstName);
  setFieldTouched("lastname", true);
  setFieldValue("address1", address1);
  setFieldTouched("address1", true);
  setFieldValue("address2", address2);
  setFieldTouched("address2", true);
  setFieldValue("city", city);
  setFieldTouched("city", true);
  setFieldValue("zip", zip);
  setFieldTouched("zip", true);
  setFieldValue("country", country);
  setFieldTouched("country", true);
  setFieldValue("state", state);
  setFieldTouched("state", true);
  setFieldValue("email", email);
  setFieldTouched("email", true);
  setFieldValue("phone", "(905) - 905 - 9055");
  setFieldTouched("phone", true);
  setFieldValue("password", "zebra123");
  setFieldTouched("password", true);
  setFieldValue("confirmPassword", "zebra123");
  setFieldTouched("confirmPassword", true);
};
const initLoginValues = {
  firstname: "",
  lastname: "",
  dob: "0000-00-00",
  phone: "",
  email: "",
  password: "",
  confirmPassword: "",
  address1: "",
  address2: "",
  city: "",
  state: "",
  country: "",
  zip: "",
  roleId: 3,
  orgId: -1,
};

const signUpSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email address")
    .required("An email is required"),
  password: Yup.string()
    .min(8, "password must be at least 8 characters")
    .required("A password is required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Please confirm your password"),
  firstname: Yup.string()
    .min(2, "A first name is required")
    .required("A first name is required"),
  lastname: Yup.string()
    .min(2, "A first name is required")
    .required("A last name is required"),
  phone: Yup.string()
    .min(18, "Phone Number must be exactly 10 digits")
    .required("A phone number is required"),
  address1: Yup.string()
    .min(2, "An address is required")
    .required("An address is required"),
  address2: Yup.string(),
  city: Yup.string().required("A city is required"),
  state: Yup.string().required("A state is required"),
  country: Yup.string().required("A country is required"),
  zip: Yup.string()
    .min(2, "A zip/postal code is required")
    .required("A zip is required"),
});

function SignUpArea({ role }) {
  const navigate = useNavigate();
  const events = useSelector((state) => state.events);
  const [showPassword, setShowPassword] = useState("");
  const [showConfirmPassword, setShowConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [sortedCountriesList, setSortedCountriesList] = useState([]);
  const [filteredRegionalList, setFilteredRegionalList] = useState([]);

  useEffect(() => {
    let newList = csc.getAllCountries().filter((x) => {
      return x.name === "Canada" || x.name === "United States";
    });
    newList.push(
      ...csc.getAllCountries().filter((x) => {
        return x.name !== "Canada" && x.name !== "United States";
      })
    );

    setSortedCountriesList(newList);
    setFilteredRegionalList(setDefaultStates());
  }, []);

  const setDefaultStates = () => {
    let combinedStatesAndProvinces = [
      ...csc.getStatesOfCountry("CA"),
      ...usStates,
    ];

    return combinedStatesAndProvinces.sort((a, b) => {
      return a.name > b.name ? 1 : a.name === b.name ? 0 : -1;
    });
  };

  const dispatch = useDispatch();

  /***
  Fix me:
  * - Add rules to password to incldue
  *     special characters
  * - Add country, state, and city select
  ***/

  const signup = async (values) => {
    /*
    Formatted Values is used to remove all the extra white spaces and
    get the phone numbers without the brackets, spaces, and dashes
    */
    let formattedValues = { ...values };
    let valuesToTrim = [
      "email",
      "password",
      "confirmPassword",
      "firstname",
      "lastname",
      "phone",
      "address1",
      "address2",
      "city",
      "zip",
    ];
    valuesToTrim.forEach((key) => {
      formattedValues[key] = trimOffTheTop(values[key]);
    });
    formattedValues.address =
      formattedValues.address1 + " " + formattedValues.address2;
    formattedValues.phone = getVanillaPhoneNumber(values.phone);
    let user = await axios.post("/api/auth/signup", formattedValues);
    return user;
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      setSubmitting(true);
      const user = await signup(values);
  
      if (user.status === 200) {
        dispatch(authActions.handleLogin(user.data));
  
        if (events && Object.keys(events.event).length > 0) {
          navigate("/team-management/create");
        } else {
          navigate("/");
        }
        setError(""); // Using global state
      }
    } catch (e) {
      if (e.status === 401) {
        setError("User Already Exists");
      } else {
        setError("Oops... something went wrong");
      }
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <section className="">
      <div className="">
        <div className="row justify-content-center">
          <div
            className="col-lg-6 col-md-4"
            //fixme
            style={{
              backgroundImage: `url("${
                process.env.PUBLIC_URL + "/assets/covers/judges_stripe.jpg"
              }")`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              minHeight: "120vh",
              display: "always",
            }}
          ></div>
          <div className="col-lg-6 col-md-8 text-center">
            <h1 className="text-dark pt-10">Join STRIPE</h1>

            <div className="ml-20 mr-20">
              <div className="register-form">
                <Formik
                  enableReinitialize={true}
                  initialValues={initLoginValues}
                  validationSchema={signUpSchema}
                  onSubmit={handleSubmit}
                >
                  {({
                    values,
                    touched,
                    errors,
                    isSubmitting ,
                    setFieldValue,
                    setFieldTouched,
                  }) => (
                    <Form className="contact-form">
                      {/**
                       * Name, Email and Password Card
                       */}
                      <div className="">
                        {/**
                         * First Name and Last Name Group
                         */}
                        <div className="form-grp">
                          <div className="row">
                            <div className="col-lg-6 col-md-6">
                              <Field
                                type="text"
                                name="firstname"
                                id="firstname"
                                placeholder="First Name"
                                className="form-control rounded"
                                onChange={(e) => {
                                  setFieldValue("firstname", e.target.value);
                                  if (e.target.value === "devSays") {
                                    let randomNum =
                                      Math.floor(Math.random() * 999) + "";
                                    setTestValues(
                                      e.target.value + randomNum,
                                      randomNum + "Bronte St S",
                                      randomNum,
                                      "Milton",
                                      randomNum +
                                        randomNum
                                          .split("")
                                          .map((digit) => {
                                            return String.fromCharCode(
                                              97 + parseInt(digit)
                                            );
                                          })
                                          .join(""),
                                      "CA",
                                      "Ontario",
                                      `asads${randomNum}@zebraroboticsed.com`,
                                      setFieldValue,
                                      setFieldTouched
                                    );
                                  } else if (e.target.value === "jaiSays") {
                                    let randomNum =
                                      Math.floor(Math.random() * 999999) + "";
                                    setTestValues(
                                      e.target.value + randomNum,
                                      randomNum + "Holly Springs Rd",
                                      randomNum,
                                      "Holly Springs",
                                      randomNum,
                                      "US",
                                      "North Carolina",
                                      "jaib@zebrarobotics.com",
                                      setFieldValue,
                                      setFieldTouched
                                    );
                                  }
                                }}
                              />
                              {errors["firstname"] && touched["firstname"] && (
                                <small className="text-danger">
                                  {errors["firstname"].toString()}
                                </small>
                              )}
                            </div>
                            <div className="col-lg-6 col-md-6">
                              <Field
                                type="text"
                                name="lastname"
                                id="lastname"
                                placeholder="Last Name"
                                className="form-control rounded"
                              />
                              {errors["lastname"] && touched["lastname"] && (
                                <small className="text-danger">
                                  {errors["lastname"].toString()}
                                </small>
                              )}
                            </div>
                          </div>
                        </div>

                        {/**
                         * Email and Phone Group
                         */}
                        <div className="form-grp">
                          <div className="row">
                            <div className="col-lg-6 col-md-6">
                              <Field
                                type="text"
                                name="phone"
                                id="phone"
                                className="form-control rounded"
                                placeholder="Phone #"
                                maxLength={18}
                                onChange={(e) => {
                                  let phoneNumber = getFormattedNumber(
                                    e.target.value
                                  );
                                  setFieldValue("phone", phoneNumber);
                                }}
                              />
                              {errors["phone"] && touched["phone"] && (
                                <small className="text-danger">
                                  {errors["phone"].toString()}
                                </small>
                              )}
                            </div>
                            <div className="col-lg-6 col-md-6">
                              <Field
                                type="email"
                                name="email"
                                id="email"
                                className="form-control rounded"
                                placeholder="Email"
                              />
                              {errors["email"] && touched["email"] && (
                                <small className="text-danger">
                                  {errors["email"].toString()}
                                </small>
                              )}
                            </div>
                          </div>
                        </div>

                        {/**
                         * Password and Confirm Group
                         */}
                        <div className="form-grp">
                          <div className="input-group">
                            <div className="row">
                              <div className="col-lg-6">
                                <Field
                                  type={showPassword ? "text" : "password"}
                                  name="password"
                                  id="password"
                                  className="form-control rounded"
                                  placeholder="Password"
                                />
                                <span
                                  className="password-toggle eye-icon"
                                  onClick={() => setShowPassword(!showPassword)}
                                >
                                  <i
                                    className={`fa ${
                                      showPassword ? "fa-eye-slash" : "fa-eye"
                                    }`}
                                  ></i>
                                </span>
                              </div>
                              <div className="col-lg-6">
                                <Field
                                  type={
                                    showConfirmPassword ? "text" : "password"
                                  }
                                  name="confirmPassword"
                                  id="confirmPassword"
                                  className="form-control rounded"
                                  placeholder="Confirm Password"
                                />
                                <span
                                  className="password-toggle eye-icon"
                                  onClick={() =>
                                    setShowConfirmPassword(!showConfirmPassword)
                                  }
                                >
                                  <i
                                    className={`fa ${
                                      showPassword ? "fa-eye-slash" : "fa-eye"
                                    }`}
                                  ></i>
                                </span>
                              </div>
                            </div>
                          </div>
                          {((errors["confirmPassword"] &&
                            touched["confirmPassword"]) ||
                            (errors["password"] && touched["password"])) && (
                            <small className="text-danger">
                              {errors["password"]
                                ? errors["password"].toString()
                                : errors["confirmPassword"].toString()}
                            </small>
                          )}
                        </div>
                      </div>

                      {/**
                       * Address and Phone Card
                       */}
                      <div className="border-top pt-30 mt-20 mb-20">
                        <div className="form-grp">
                          <div className="row">
                            <div className="col-lg-12 col-md-12">
                              <Field
                                type="text"
                                name="address1"
                                id="address1"
                                placeholder="Address"
                                className="form-control rounded"
                              />
                              {errors["address1"] && touched["address1"] && (
                                <small className="text-danger">
                                  {errors["address1"].toString()}
                                </small>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="form-grp">
                          <div className="row">
                            <div className="col-lg-12 col-md-12">
                              <Field
                                type="text"
                                name="address2"
                                id="address2"
                                placeholder="Address 2"
                                className="form-control rounded"
                              />
                              {errors["address2"] && touched["address2"] && (
                                <small className="text-danger">
                                  {errors["address2"].toString()}
                                </small>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="">
                          <div className="row">
                            <div className="col-lg-6 col-md-6">
                              <select
                                name="country"
                                component="select"
                                className="bg-white border-0 rounded"
                                id="country"
                                placeholder="Country"
                                value={values.country}
                                onChange={(e) => {
                                  let selectedCountry = csc
                                    .getAllCountries()
                                    .filter((x) => {
                                      return x.isoCode === e.target.value;
                                    })[0];
                                  let value = selectedCountry
                                    ? selectedCountry.isoCode
                                    : "";
                                  setFieldValue("country", value);
                                  let filtered =
                                    e.target.value === ""
                                      ? setDefaultStates()
                                      : e.target.value === "US"
                                      ? usStates
                                      : csc.getStatesOfCountry(e.target.value);

                                  setFilteredRegionalList(filtered);
                                }}
                              >
                                <option value="">Select A Country</option>

                                {sortedCountriesList.map((i) => (
                                  <option key={i.name} value={i.isoCode}>
                                    {i.name}
                                  </option>
                                ))}
                              </select>
                              {errors["country"] && touched["country"] && (
                                <small className="text-danger">
                                  {errors["country"].toString()}
                                </small>
                              )}
                            </div>

                            <div className="col-lg-6 col-md-6">
                              <select
                                name="state"
                                component="select"
                                id="state"
                                value={values.state}
                                placeholder="State"
                                className="bg-white border-0 rounded"
                                onChange={(e) => {
                                  setFieldValue("state", e.target.value);
                                }}
                              >
                                <option value="">
                                  Select A State/Province
                                </option>

                                {filteredRegionalList.map((i, index) => (
                                  <option key={i.name} value={i.name}>
                                    {i.name}
                                  </option>
                                ))}
                              </select>
                              {errors["state"] && touched["state"] && (
                                <small className="text-danger">
                                  {errors["state"].toString()}
                                </small>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="form-grp">
                          <div className="row">
                            <div className="col-lg-6 col-md-6">
                              <Field
                                type="text"
                                name="city"
                                id="city"
                                placeholder="City"
                                className="form-control rounded"
                              />
                              {errors["city"] && touched["city"] && (
                                <small className="text-danger">
                                  {errors["city"].toString()}
                                </small>
                              )}
                            </div>
                            <div className="col-lg-6 col-md-6">
                              <Field
                                type="text"
                                name="zip"
                                id="zip"
                                placeholder="Zip/Postal Code"
                                className="form-control rounded"
                              />
                              {errors["zip"] && touched["zip"] && (
                                <small className="text-danger">
                                  {errors["zip"].toString()}
                                </small>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>

                      {/**
                       * Action Buttons
                       */}
                      {error !== "" && (
                        <div>
                          <small className="text-danger">{error}</small>
                        </div>
                      )}
                      <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                        {isSubmitting ? "Creating Account..." : "Create Account"}
                      </button>
                      <a href="/login" className="pl-20">
                        Login
                      </a>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default SignUpArea;
