import { createSlice } from "@reduxjs/toolkit";

/****************************************
 * Events Slice
 * This is the slice in charge of
 * storing the event a user is trying to
 * sign up for until they are logged in
 * or register
 *****************************************/
export const eventsIntialState = {
  event: {},
  selectedChoice: {},
  selectedTeam: {},
};

export const eventsSlice = createSlice({
  name: "events",
  initialState: eventsIntialState,
  reducers: {
    setEvent(state, action) {
      state.event = { ...action.payload };
      state.selectedChoice = {};
      state.selectedTeam = {};
    },
    setEventChoice(state, action) {
      state.selectedChoice = { ...action.payload };
    },
    setEventTeam(state, action) {
      state.selectedTeam = action.payload;
    },
    removeEvent(state) {
      state.event = {};
      state.selectedChoice = {};
      state.selectedTeam = {};
    },
  },
});

export const getEventObj = (events) => {
  if (
    Object.keys(events.event).length <= 0 ||
    Object.keys(events.selectedChoice).length <= 0
  ) {
    return {};
  }
  let event = events.event;
  let eventId = events.event.eventId;
  let chosenStream = event.streams.filter((s) => {
    return s.streamId === events.selectedChoice.streamId;
  })[0];
  let chosenAC = chosenStream.age_categories.filter((ac) => {
    return ac.ageCategoryId === events.selectedChoice.ageCategoryId;
  })[0];
  let chosenTime = chosenAC.times.filter((t) => {
    return (
      t.eventTimeSlotsId === parseInt(events.selectedChoice.eventTimeSlotsId)
    );
  })[0];
  return {
    eventId,
    ...chosenStream,
    ...chosenAC,
    ...chosenTime,
  };
};

export const eventsActions = eventsSlice.actions;
