import { createSlice } from "@reduxjs/toolkit";

/****************************************
 * Auth Slice
 * This is the slice in charge of
 * tracking who is logged in and storing
 * thier main access data used for
 * naviating around the website
 *****************************************/
export const authIntialState = {
  isLoggedIn: false,
  user: {},
  accessToken: "",
  organizations: [],
  teams: [],
};

export const authSlice = createSlice({
  name: "auth",
  initialState: authIntialState,
  reducers: {
    handleLogin(state, action) {
      state.isLoggedIn = true;
      state.user = { ...action.payload.user };
      state.accessToken = action.payload.accessToken;
      state.organizations = { ...action.payload.organizations };
      state.teams = [...action.payload.teams];
    },
    handleLogout(state) {
      state.isLoggedIn = false;
      state.user = {};
      state.accessToken = "";
      state.organizations = [];
      state.teams = [];
    },
  },
});

export const authActions = authSlice.actions;
