import React, { Suspense, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { getRoutes } from "./Routes";
import axios from "axios";
function App() {
  const auth = useSelector((state) => state.auth);

  // Set the interceptor once when the component is mounted
  useEffect(() => {
    const interceptor = axios.interceptors.request.use(
      (config) => {
        const authToken = auth.accessToken;
        if (authToken) {
          config.headers["x-auth-token"] = authToken; // Attach token to request headers
        }
        return config;
      },
      (err) => Promise.reject(err)
    );

    // Cleanup function to remove the interceptor when the component unmounts
    return () => {
      axios.interceptors.request.eject(interceptor);
    };
  }, [auth.accessToken]); // Only re-run the effect if `auth.accessToken` changes

  const basename = process.env.PUBLIC_URL ? `${process.env.PUBLIC_URL}/` : "/";
  const router = createBrowserRouter(getRoutes(auth), { basename });

  return (
    <Suspense
      fallback={
        <div class="d-flex justify-content-center align-items-center h-100">
          <Spinner animation="grow" role="status" size="lg" variant="primary" />
        </div>
      }
    >
      <RouterProvider router={router} />
    </Suspense>
  );
}

export default App;
