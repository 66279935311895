// ArrowWidget.js
import React from "react";

const ArrowHeader = ({ title }) => {
  const arrow1Src =
    process.env.PUBLIC_URL + "/assets/covers/stripe_arrow_left.png";
  const arrow2Src =
    process.env.PUBLIC_URL + "/assets/covers/stripe_arrow_right.png";

  return (
    <div className="row">
      <div className="col-12">
        <h3 className="section-title">
          <img src={arrow1Src} alt="Arrow" />
          <span>{title}</span>
          <img src={arrow2Src} alt="Arrow" />
        </h3>
      </div>
    </div>
  );
};

export default ArrowHeader;
