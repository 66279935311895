export const usStates = [
  { name: "Alabama", isoCode: "AL" },
  { name: "Alaska", isoCode: "AK" },
  { name: "Arizona", isoCode: "AZ" },
  { name: "Arkansas", isoCode: "AR" },
  { name: "California", isoCode: "CA" },
  { name: "Colorado", isoCode: "CO" },
  { name: "Connecticut", isoCode: "CT" },
  { name: "Delaware", isoCode: "DE" },
  { name: "District of Columbia", isoCode: "DC" },
  { name: "Florida", isoCode: "FL" },
  { name: "Georgia", isoCode: "GA" },
  { name: "Hawaii", isoCode: "HI" },
  { name: "Idaho", isoCode: "ID" },
  { name: "Illinois", isoCode: "IL" },
  { name: "Indiana", isoCode: "IN" },
  { name: "Iowa", isoCode: "IA" },
  { name: "Kansas", isoCode: "KS" },
  { name: "Kentucky", isoCode: "KY" },
  { name: "Louisiana", isoCode: "LA" },
  { name: "Maine", isoCode: "ME" },
  { name: "Maryland", isoCode: "MD" },
  { name: "Massachusetts", isoCode: "MA" },
  { name: "Michigan", isoCode: "MI" },
  { name: "Minnesota", isoCode: "MN" },
  { name: "Mississippi", isoCode: "MS" },
  { name: "Missouri", isoCode: "MO" },
  { name: "Montana", isoCode: "MT" },
  { name: "Nebraska", isoCode: "NE" },
  { name: "Nevada", isoCode: "NV" },
  { name: "New Hampshire", isoCode: "NH" },
  { name: "New Jersey", isoCode: "NJ" },
  { name: "New Mexico", isoCode: "NM" },
  { name: "New York", isoCode: "NY" },
  { name: "North Carolina", isoCode: "NC" },
  { name: "North Dakota", isoCode: "ND" },
  { name: "Ohio", isoCode: "OH" },
  { name: "Oklahoma", isoCode: "OK" },
  { name: "Oregon", isoCode: "OR" },
  { name: "Pennsylvania", isoCode: "PA" },
  { name: "Rhode Island", isoCode: "RI" },
  { name: "South Carolina", isoCode: "SC" },
  { name: "South Dakota", isoCode: "SD" },
  { name: "Tennessee", isoCode: "TN" },
  { name: "Texas", isoCode: "TX" },
  { name: "Utah", isoCode: "UT" },
  { name: "Vermont", isoCode: "VT" },
  { name: "Virginia", isoCode: "VA" },
  { name: "Washington", isoCode: "WA" },
  { name: "West Virginia", isoCode: "WV" },
  { name: "Wisconsin", isoCode: "WI" },
  { name: "Wyoming", isoCode: "WY" },
];
