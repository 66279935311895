import React from "react";
import { Link, useMatches } from "react-router-dom";
import "./Breadcrumbs.scss";

function Breadcrumbs() {
  const matches = useMatches();

  return (
    <nav aria-label="Breadcrumbs" className="bg-banner">
      <ol className="breadcrumb">
        {matches.map((match, index) => (
          <li key={match.id} className="breadcrumb-item">
            {index === 0 ? (
              <>
                <Link to={"/"}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-house"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8.707 1.5a1 1 0 0 0-1.414 0L.646 8.146a.5.5 0 0 0 .708.708L2 8.207V13.5A1.5 1.5 0 0 0 3.5 15h9a1.5 1.5 0 0 0 1.5-1.5V8.207l.646.647a.5.5 0 0 0 .708-.708L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293zM13 7.207V13.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V7.207l5-5z" />
                  </svg>
                </Link>
                <Link
                  className="pt-1 pl-10 uppercase"
                  to={match.pathname}
                  disabled={true}
                >
                  {match.handle?.crumb ??
                  match.pathname.includes("/reset-password/")
                    ? "Reset-Password"
                    : match.pathname.replace("/", " ")}
                </Link>
              </>
            ) : (
              <Link
                to={match.pathname}
                className="uppercase"
                disabled={index === match.length - 1}
              >
                {match.handle?.crumb ??
                match.pathname.includes("/reset-password/")
                  ? "Reset-Password"
                  : match.pathname.replace("/", " ")}
              </Link>
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
}

export default Breadcrumbs;
