import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import React, { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import App from "./App";
import "./assets/scss/style.scss";
import store, { persistor } from "./store/store";

/****************************************
 * Print Logo
 * Just a fun message in he console
 *****************************************/
function printLogo() {
  console.log("  __    __    __   ");
  console.log("  \\ \\   \\ \\   \\ \\  ");
  console.log("   \\ \\   \\ \\   \\ \\ ");
  console.log("   / /   / /   / / ");
  console.log("  /_/   /_/   /_/  ");
  console.log("                   ");
  console.log("");
  console.log("__________________________________________________________");
  console.log(
    "/   _____/\\__    ___/\\______   \\ |   |\\______   \\_   _____/"
  );
  console.log("\\_____  \\   |    |    |       _/ |   ||     ___/|    __)_ ");
  console.log("/        \\  |    |    |    |   \\ |   ||    |    |        \\");
  console.log("/_______  /  |____|    |____|_  /|___||____|   /_______  /");
  console.log("");
  console.log("__________________________________________________________");
}

printLogo();
console.log("Why do programmers like dark mode?\nBecause light attracts bugs.");

/****************************************
 * Axios Defaults
 * This is setting the baseURL and
 * content type default configs
 * for axios so we don't have to do it
 * on each call
 *****************************************/
axios.defaults.baseURL = process.env.REACT_APP_SERVER_DOMAIN;
axios.defaults.headers.post["Content-Type"] = "application/json";

/****************************************
 * Root
 *****************************************/
const root = createRoot(document.getElementById("root"));
root.render(
  <StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </StrictMode>
);
