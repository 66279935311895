import React from "react";
import Breadcrumbs from "../../../Components/Breadcrumbs/Breadcrumbs.js";
import ArrowHeader from "../../../Widgets/ArrowHeader.js";
import ContactForm from "./ContactForm.js";
import EthicsBar from "./EthicsBar.js";

export const AboutSection = () => {
  return (
    <div className="rounded ml-10 mr-10 bg-gray mt-20 p-10 pb-0 pt-20 mb-40">
      <div className="row">
        <div className="col-12">
          <ArrowHeader title="About STRIPE!" />
        </div>
      </div>
      <div className="row bg-gray-2">
        <div className="col-lg-6 col-sm-12 justify-content-center">
          {/* Add Text Here */}
          <p className="section-text">
            Since 2022, the STRIPE Competition has been the ultimate playground
            for students aged 6-18 to show off their epic skills in robotics,
            coding, and tech wizardry. Whether you’re building bots, cracking
            coding challenges, or competing with teams across North America,
            STRIPE is where the action happens. Jump in, level up, and see if
            you’ve got what it takes to top the leaderboards and have a blast
            doing it
          </p>
        </div>
        <div className="col-lg-6 col-sm-12">
          {" "}
          <div className="video-frame p-10">
            <iframe
              width="25%"
              height="100%"
              src="https://www.youtube.com/embed/P-qaVEr-p5s?si=f71SlYr8GThxLo1X&rel=0"
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
};

function AboutBody() {
  return (
    <div>
      <div
        //fixme
        style={{
          backgroundImage: `url(${
            process.env.PUBLIC_URL + "/assets/covers/botm-kids-cheering-min.jpg"
          })`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: "50vh",
        }}
      ></div>
      <div className="container">
        <Breadcrumbs />
        {/*About STRIPE Section*/}
        <AboutSection />

        {/*Goal Section*/}
        <ArrowHeader title="Goal" />

        <div className="row">
          <div className="col-12">
            <div className="text-center">
              <p className="pt-30 ml-60 mr-60">
                {/* Add Text Here */}
                At STRIPE Competition, we value not just the winning but the
                human skills developed through competition. We strive to create
                inclusive, engaging, challenging, and supportive competitions
                that allow students to test new ideas, learn from failure, and
                become invested in the spirit of innovation. We award winners,
                but we develop mature competitors who will be able to
                collaborate with others and approach challenges with persistence
                and grit.
              </p>
              <img
                src={process.env.PUBLIC_URL + "/assets/covers/home-page-2.png"}
                alt="Goal"
                style={{
                  display: "block",
                  margin: "0 auto",
                  width: "100vw",
                  height: "auto",
                  maxWidth: "40rem",
                }}
              />
            </div>
          </div>
        </div>

        {/*Ethics Section*/}
        <EthicsBar />

        {/*Contact Section*/}

        <div id="contact-us" className="row pt-40">
          <ArrowHeader title="Contact Us" />
          <div className="offset-1 col-10 pb-10">
            <p className="p-centered pb-10">
              <br />
              We'd love to hear from you! Whether you have a question, feedback,
              or just want to say hello, please don’t hesitate to reach out. Our
              team is here to assist you and provide the support you need.
            </p>
          </div>
          <br />
          <div>
            <div className="pb-10">
              <div className="row">
                <div className="col-lg-4 pb-50">
                  <div className="p-20 contact-us">
                    <div className="row ml-20">
                      <div className="col-lg-3 col-sm-2 pt-1">
                        <div className="about-icon w-p-100">
                          <i className="fa fa-phone" />
                        </div>
                      </div>
                      <div className="col-lg-7 col-sm-8">
                        <a href="tel:+16474777410">(647) 477 - 7410 (Canada)</a>
                        <br />
                        <a href="tel:+19196506333">(919) 650 – 6333 (US)</a>
                      </div>
                    </div>
                    <div className="row pt-35 ml-20">
                      <div className="col-lg-3 col-sm-2">
                        <div className="about-icon ">
                          <i className="fa fa-globe" />
                        </div>
                      </div>
                      <div className="col-lg-7 col-sm-8 pt-1">
                        <div className="contact-info-dec">
                          <a href="mailto:info@stripecompetition.com">
                            info@stripecompetition.com
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="row ml-20">
                      <div className="col-lg-3 col-sm-2 mt-50">
                        <div className="about-icon " style={{ height: "100%" }}>
                          <i className="fa fa-map-marker" />
                        </div>
                      </div>
                      <div className="col-lg-7 col-sm-8 pt-15">
                        <div className="contact-info-dec">
                          <a
                            href={
                              "http://maps.google.com/?q=Zebra Robotics " +
                              "6635 Kitimat Rd #34" +
                              `%20Mississauga,%20$ON%20L5N6J2`
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            6635 Kitimat Rd #34, <br /> Mississauga, ON, L5N
                            6J2, Canada
                          </a>
                        </div>
                        <div className="contact-info-dec mt-2">
                          <a
                            href={
                              "http://maps.google.com/?q=Zebra Robotics " +
                              "1408 Boulderstone Way" +
                              `Q5R2+XW Cary, North Carolina, USA`
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            1408 Boulderstone Way,
                            <br /> Cary, NC 27519, USA
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* contact form */}
                <div className="col-lg-7 col-md-12 about-container">
                  <div className="form-corner form-top-left">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/covers/yellow-top-left.png"
                      }
                      alt="Decorative Border"
                    />
                  </div>
                  <div className="form-corner form-top-right">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/covers/yellow-top-right.png"
                      }
                      alt="Decorative Border"
                    />
                  </div>
                  <div className="form-corner form-bottom-left">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/covers/yellow-bottom-left.png"
                      }
                      alt="Decorative Border"
                    />
                  </div>
                  <div className="form-corner form-bottom-right">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/covers/yellow-bottom-right.png"
                      }
                      alt="Decorative Border"
                    />
                  </div>
                  <div className=" contact-form">
                    <ContactForm />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutBody;
