import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Breadcrumbs from "../../Components/Breadcrumbs/Breadcrumbs";
import LayoutTwo from "../../Components/Layout/LayoutTwo";
import ScrollToHashWithOffset from "../../helpers/scroll-to-hash";
import SeasonsMainPage from "./Widgets/SeasonsMainPage";
const SeasonsPage = () => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>Season | Stripe Competition</title>
        <meta
          name="description"
          content="Coding, Robotics, and Technology Competition."
        />
      </Helmet>
      <LayoutTwo
        headerContainerClass="container-fluid"
        headerPaddingClass="header-padding"
      >
        <div className="seasonBanner">
          <img
            src="/assets/covers/AnimalJourneysTextImage.png"
            alt="Season Header"
          />
        </div>

        <Breadcrumbs />
        <div className="container-fluid">
          {/*


          Season intro
          Competition Levels breakdown
          Dates in the competition
          
          Resources (mat image, parts, youtube robot videos, coaches documents, rules)


          Intro to the season - animated video? link to registration
          
          competition breaksdown 

          Challenge details - link to cahllenges, materials , rules ]

          other resources section -  videos, coach documents 

          eventually a past challenges secton
          sponsers?

          maybe a nav bar pop up
          
               <SeasonChallenges />'

          */}
          <SeasonsMainPage />
          <ScrollToHashWithOffset />
        </div>
      </LayoutTwo>
    </HelmetProvider>
  );
};

export default SeasonsPage;
