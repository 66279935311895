import { Field, useFormikContext } from "formik";
import React, { useState } from "react";
import { InputGroup } from "react-bootstrap";

const getValues = (obj, name) => {
  if (name.indexOf(".") >= 0) {
    let currentObj = obj;
    let splitValues = name.split(".");
    for (let i = 0; i < splitValues.length && currentObj; i++) {
      currentObj = currentObj[splitValues[i]];
    }
    return currentObj;
  } else {
    return obj[name];
  }
};

export function PasswordField({ ...props }) {
  const [showPassword, setShowPassword] = useState("");
  const { errors, touched } = useFormikContext();
  let touchedVal = getValues(touched, props.name);
  let errorsVal = getValues(errors, props.name);
  return (
    <div className={`${props.classNames}`}>
      {props.label && props.label.length > 0 && (
        <label className="text-muted">{props.label.toUpperCase()}</label>
      )}
      <Field
        name={props.name}
        type={props.type}
        className={"form-control " + props.classNames}
        {...props}
        render={({ field }) => {
          return (
            <InputGroup>
              <input
                {...field}
                type={showPassword ? "text" : "password"}
                className={"form-control " + props.classNames}
              />
              <InputGroup.Text id="basic-addon2">
                <span onClick={() => setShowPassword(!showPassword)}>
                  <i
                    className={`fa ${showPassword ? "fa-eye-slash" : "fa-eye"}`}
                  ></i>
                </span>
              </InputGroup.Text>
            </InputGroup>
          );
        }}
      />

      {errors &&
        touched &&
        Object.keys(touched).length > 0 &&
        Object.keys(errors).length > 0 &&
        touchedVal &&
        errorsVal && (
          <div>
            <small className="text-danger">{errorsVal}</small>
          </div>
        )}
    </div>
  );
}
