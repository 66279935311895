import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import LayoutTwo from "../../Components/Layout/LayoutTwo";
import MainPage from "./Widgets/MainPage";

const MainLandingPage = () => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>Stripe Competition</title>
        <meta
          name="description"
          content="Coding, Robotics, and Technology Competition."
        />
      </Helmet>
      <LayoutTwo>
        <MainPage/>
      </LayoutTwo>
    </HelmetProvider>
  );
};

export default MainLandingPage;
