import PropTypes from "prop-types";
import FunFactOneSingle from "./FactSingle";

const FunFactOne = ({ spaceTopClass, spaceBottomClass, bgClass }) => {
  const data = {
    item: {
      iconClass: "fa fa-users text-dark",
      countNum: 50,
      title: "Teams",
    },
    item2: {
      iconClass: "fa fa-calendar-plus-o text-dark",
      countNum: 30,
      title: "Events",
    },
    item3: {
      iconClass: "fa fa-map-o fa-lg text-dark",
      countNum: 15,
      title: "Cities",
    },
  };

  return (
    <div
      id={"funfact"}
      className={`funfact-area ${spaceTopClass ? spaceTopClass : ""} ${
        spaceBottomClass ? spaceBottomClass : ""
      } ${bgClass ? bgClass : ""}`}
      style={{
        borderRadius: "2rem",
        marginRight: "1rem",
        marginLeft: "1rem",
        marginBottom: "1rem",
      }}
    >
      <div className="container">
        <div className="row">
          {data &&
            Object.values(data).map((single, key) => {
              return (
                <FunFactOneSingle
                  data={single}
                  spaceBottomClass="mb-30"
                  key={key}
                  textAlignClass="text-center"
                />
              );
            })}
        </div>
      </div>

      <div className="form-corner form-top-left">
        <img
          src={process.env.PUBLIC_URL + "/assets/covers/blue-top-left.png"}
          alt="Decorative Border"
        />
      </div>
      <div className="form-corner form-top-right">
        <img
          src={process.env.PUBLIC_URL + "/assets/covers/blue-top-right.png"}
          alt="Decorative Border"
        />
      </div>
      <div className="form-corner form-bottom-left">
        <img
          src={process.env.PUBLIC_URL + "/assets/covers/blue-bottom-left.png"}
          alt="Decorative Border"
        />
      </div>
      <div className="form-corner form-bottom-right">
        <img
          src={process.env.PUBLIC_URL + "/assets/covers/blue-bottom-right.png"}
          alt="Decorative Border"
        />
      </div>
    </div>
  );
};

FunFactOne.propTypes = {
  bgClass: PropTypes.string,
  spaceBottomClass: PropTypes.string,
  spaceTopClass: PropTypes.string,
};

export default FunFactOne;
