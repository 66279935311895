import { combineReducers, configureStore, createSlice } from "@reduxjs/toolkit";
import moment from "moment/moment";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { authSlice } from "./auth/authSlice";
import { eventsSlice } from "./events/eventsSlice";

/****************************************
 * User Data Slice
 * This is the local storage pertaining
 * to the user's local storage meta data
 *
 * timeSet: the time the user's local
 * storage is set
 *****************************************/
const userDataState = {
  timeSet: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
};

const userDataSlice = createSlice({
  name: "userData",
  initialState: userDataState,
  reducers: {
    setData(state, action) {
      state = { ...action.payload };
    },
  },
});

export const userDataActions = userDataSlice.actions;

/****************************************
 * App Reducer
 * This is THE local storage to persist
 *****************************************/
const appReducer = combineReducers({
  auth: authSlice.reducer,
  events: eventsSlice.reducer,
  userData: userDataSlice.reducer,
});

/* Start Persistance Logic */
const persistConfig = {
  key: "v1Stripe-root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, appReducer);

/* End Persistance Logic */

/****************************************
 * Store
 * This is the actual data wrapped
 * around the entire website
 *****************************************/
const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export const persistor = persistStore(store);
export default store;
