import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import LayoutTwo from "../../Components/Layout/LayoutTwo";

const Error404 = () => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>ERROR 404 | Stripe Competition</title>
        <meta
          name="description"
          content="Coding, Robotics, and Technology Competition."
        />
      </Helmet>
      <LayoutTwo
        headerContainerClass="container-fluid"
        headerPaddingClass="header-padding"
      >
        <div
          className="single-slide bg-img"
          style={{
            backgroundImage: `url(${
              process.env.PUBLIC_URL + "/assets/covers/ERROR404.png"
            })`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            height: "100vh",
          }}
        ></div>
      </LayoutTwo>
    </HelmetProvider>
  );
};

export default Error404;
