import { useEffect } from "react";

const ScrollToHashWithOffset = () => {
  useEffect(() => {
    const handleScrollToHash = () => {
      const hash = window.location.hash;

      if (hash) {
        setTimeout(() => {
          const element = document.querySelector(hash);
          if (element) {
            window.scrollTo({
              top: element.offsetTop - 120,
              behavior: "smooth",
            });
          }
        }, 0);
      }
    };

    handleScrollToHash();

    window.addEventListener("hashchange", handleScrollToHash);

    return () => {
      window.removeEventListener("hashchange", handleScrollToHash);
    };
  }, []);

  return null;
};

export default ScrollToHashWithOffset;
