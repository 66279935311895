import React from "react";
import {
  Accordion,
  Card,
  CardGroup,
  useAccordionButton,
} from "react-bootstrap";
import { FaChevronRight } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import ArrowHeader from "../../../Widgets/ArrowHeader";
import StreamsWithDetails from "../../../Widgets/StreamsWithDetails";
import "./seasons.css";

const WelcomeSection = () => {
  return (
    <>
      {/* START Welcome to STRIPE Section */}
      <div className="row pb-10 rounded bg-gray-blue-2">
        <div id="current-season" className="pb-10 pt-20">
          <ArrowHeader title="Welcome to Animal Journey" />
        </div>
      </div>
      <div className="row pb-10 justify-content-center  bg-gray-blue-2">
        <div className="offset-lg-1 col-lg-4 col-md-6 col-sm-12">
          <div>
            <div className="video-frame">
              <iframe
                src="https://www.youtube.com/embed/8NJ2kXhlQKw?si=UBV2dCU8Vs46x52Z&rel=0"
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-sm-12 rounded p-10 pt-50">
          <p className="justify-content-center section-text">
            {/* Add Text Here */}
            Welcome to STRIPE Animal Journey Season! Get ready to dive into the
            wild world of animals and join them on their incredible adventures.
            From leaping over rivers to navigating dense forests, each mission
            is a chance to help our furry, feathered, and finned friends on
            their epic quests. Can your robot help a turtle cross the beach or
            guide a herd of elephants through the savannah?
            <br /> <br />
            It’s time to put your creativity, teamwork, and programming skills
            to the test. Compete alongside fellow innovators as you tackle
            challenges and showcase your unique solutions. Together, you’ll
            inspire one another and discover new ways to make a positive impact
            on the animal kingdom! <br />
            <Link
              to={process.env.PUBLIC_URL + "/events"}
              className="btn btn-primary mt-10"
            >
              Register Now
            </Link>
          </p>
        </div>
      </div>

      {/* END Welcome to STRIPE Section */}
    </>
  );
};

const LeagueSystem = () => {
  return (
    <div id="leagues" className="pt-20 rounded bg-gray-blue-2">
      <div className="row">
        <div className="col-12">
          <ArrowHeader title="League System" />
        </div>
      </div>
      <div className="row justify-content-center">
        {/* <div className="col-6">
          <div className="video-frame">
            <iframe
              height="300"
              src="https://www.youtube.com/embed/<ID>&rel=0" 
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </div> */}
        <br />
        <div className="competition-block pl-50 pr-50 pb-20 pt-2 col-12">
          <h3 style={{textAlign: "center" }}>
            STRIPE Competition Levels: From Local to Global Adventure
          </h3>
          <p style={{textAlign: "center" }}>
            The STRIPE Competition offers three exciting levels of play, each
            designed to inspire, challenge, and grow students’ skills.
          </p>
          <h4>House Leagues</h4>
          <p>House Leagues are the perfect starting point for anyone—schools,
          community groups, or even families—to dive into the excitement of
          organized competition. These leagues allow teams to compete remotely
          from their own location and track their progress on an online
          leaderboard. Whether it’s a school challenge, a community showdown,
          or a family rivalry, House Leagues make it easy and fun to join the
          action and climb the ranks! 
          </p>
          <h4>Divisional Leagues</h4>
          <p>Ready to take it to the next level? Divisional Leagues are
          in-person events that bring teams together from across a region or
          city for thrilling competition. These events include a surprise
          day-of challenge, testing teams’ creativity and problem-solving
          under pressure. Teams can choose to sign up and participate, with
          top-performing groups earning the opportunity to advance to the
          prestigious International Tournaments!
          </p>
          <h4>International Tournaments</h4>
         <p>The grand stage! International Tournaments unite high scoring
          divisional participating teams from around the globe for a
          celebration of innovation, teamwork, and cultural diversity.
          Participants compete on an international stage, showcasing their
          skills and embodying STRIPE’s core values while forming connections
          with peers from all over the world. From remote competitions with
          online leaderboards to in-person showdowns, STRIPE empowers teams to
          aim high, collaborate, and grow into global competitors!
          </p> 
        </div>
      </div>
    </div>
  );
};

const SeasonResourceCards = () => {
  return (
    <div id="coach-rules" className="pt-20 pb-10">
      <div className="row">
        <div className="col-12">
          <ArrowHeader title="Season Resources" />
        </div>
      </div>
      <div className="row">
        <div className="offset-1 col-10">
          <CardGroup>
            <Card className="season-card">
              <Card.Img
                variant="top"
                src={process.env.PUBLIC_URL + "/assets/covers/nebula1-min.png"}
                alt="mixed vegetable salad in a mason jar."
              />
              <Card.Body>
                <Card.Title className="card-title">Coach Resources</Card.Title>
                <Card.Text className="season-card-text">
                  Unlock your team's potential with our Coach Resources! Gain
                  access to essential tools, lesson plans, and expert tips
                  designed to inspire creativity, foster collaboration, and
                  ensure a rewarding experience for every participant.
                </Card.Text>
              </Card.Body>
              <Card.Footer>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <a
                    href="https://websites-events.s3.us-east-2.amazonaws.com/Animal+Journey+-+Build+Instructions/Animal+Journey_WEDO_Teacher+Support+Guide+1.1.pdf"
                    className="ResourcesButton"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    CLICK HERE TO VIEW
                  </a>
                </div>
              </Card.Footer>
            </Card>
            <Card className="season-card">
              <Card.Img
                variant="top"
                src={process.env.PUBLIC_URL + "/assets/covers/star1-min.png"}
                alt="mixed vegetable salad in a mason jar."
              />
              <Card.Body>
                <Card.Title className="card-title">Rules</Card.Title>
                <Card.Text className="season-card-text">
                  Familiarize yourself with the competition rules to ensure a
                  fair and engaging experience for all participants. Our
                  guidelines cover key protocols, scoring criteria, and safety
                  measures. By following these rules, we promote teamwork,
                  integrity, and a positive atmosphere, setting the stage for an
                  exciting and respectful challenge. Let's make this competition
                  fun and memorable for everyone!
                </Card.Text>
              </Card.Body>
              <Card.Footer>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Link
                    to="/season-challenges"
                    className="ResourcesButton"
                    state={{ seasonId: 1 }}
                  >
                    CLICK HERE TO VIEW
                  </Link>
                </div>
              </Card.Footer>
            </Card>
            <Card className="season-card">
              <Card.Img
                variant="top"
                src={process.env.PUBLIC_URL + "/assets/covers/supernova1-min.png"}
                alt="mixed vegetable salad in a mason jar."
              />
              <Card.Body>
                <Card.Title className="card-title">Purchase Materials</Card.Title>
                <Card.Text className="season-card-text">
                  Gear up for success by sourcing the perfect materials for your
                  projects! Our curated list of recommended supplies covers
                  everything from building blocks to electronic components,
                  helping you find exactly what you need. With the right
                  materials, your creativity can truly shine—let’s build
                  something amazing together!
                </Card.Text>
              </Card.Body>
              <Card.Footer>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    pointerEvents: "none",
                  }}
                >
                  <Link to="/season-challenges" className="ResourcesButton">
                    TO BE RELEASED
                  </Link>
                </div>
              </Card.Footer>
            </Card>
          </CardGroup>
        </div>
      </div>
    </div>
  );
};

const ChallengeCard = ({ s, sIndex, handleClick }) => {
  const decoratedOnClick = useAccordionButton(sIndex);

  return (
    <Card className="challenge-card">
      <Card.Header
        onClick={() => {
          decoratedOnClick();
          handleClick(s);
        }}
        className="challenge-headers"
      >
        {s.title}
        <span className="chevron-icon">
          <FaChevronRight />
        </span>
      </Card.Header>
      <Accordion.Collapse eventKey={sIndex}>
        <Card.Body className="challenge-body">
          <div className="row">
            <div className="col-12">Results Place Holder</div>
          </div>
        </Card.Body>
      </Accordion.Collapse>
    </Card>
  );
};

const SeasonChallenges = () => {
  const navigate = useNavigate();
  let seasons = [
    // {
    //   seasonId: 2,
    //   title: "Alien Planet (2023-2024)",
    //   results: [],
    //   challenges: [],
    // },
    // {
    //   seasonId: 3,
    //   title: "Water and Sustainability (2022-2023)",
    //   results: [],
    //   challenges: [],
    // },
    {
      seasonId: 1,
      title: "Animal Journey (2025)",
      results: [],
      challenges: [],
    },
  ];

  const handleClick = (s) => {
    if (
      s.title === "Animal Journey (2025)"
      // s.title === "Alien Planet (2023-2024)" ||
      // s.title === "Water and Sustainability (2022-2023)"
    ) {
      navigate("/season-challenges", { state: { seasonId: s.seasonId } });
    }
  };
  return (
    <div className="rounded p-10 pb-20 mb-10 bg-gray-2">
      <div id="season-challenges" className="row pt-10 pb-10">
        <div className="col-12">
          <ArrowHeader title="Season Challenges" />
        </div>
      </div>
      <Accordion defaultActiveKey="0">
        {seasons.map((s, sIndex) => {
          return (
            <div className="row" key={sIndex}>
              <div className="col-12">
                <ChallengeCard
                  s={s}
                  sIndex={sIndex}
                  handleClick={handleClick}
                />
              </div>
            </div>
          );
        })}
      </Accordion>
    </div>
  );
};

const SeasonsMainPage = () => {
  return (
    <div className="container-fluid">
      <WelcomeSection />
      <StreamsWithDetails />
      <LeagueSystem />
      <SeasonResourceCards />
      <SeasonChallenges />
    </div>
  );
};

export default SeasonsMainPage;
