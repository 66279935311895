/**
 * Forgot Password Area
 * @desc The contents of the forgot passwrd page
 * @component
 * @public
 *
 * @param None
 *
 * @todo
 * - Fix error handling
 *
 * @example
 * Note: Remember to wrap in a Layout
 *       component
 * <ForgotPasswordArea />
 *
 * @author Asad Siddiqui
 * @created_on 2024-12-10
 * @modified_on 2024-12-10
 */
import axios from "axios";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Alert } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import Breadcrumbs from "../../../Components/Breadcrumbs/Breadcrumbs";
import { PasswordField } from "../../../Widgets/FormHelpers/PasswordField";
import { trimOffTheTop } from "../../../helpers/formatter";

function ResetPasswordArea() {
  let { token } = useParams();
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [email, setEmail] = useState("");
  const [tokenError, setTokenError] = useState(false);

  const initForm = {
    email: "",
    password: "",
    confirmPassword: "",
  };

  const resetSchema = Yup.object().shape({
    password: Yup.string()
      .min(8, "password must be at least 8 characters")
      .required("A password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Please confirm your password"),
  });

  const resetPassword = async (values) => {
    setError("");
    let formattedValues = JSON.parse(JSON.stringify(values));
    formattedValues.email = email;
    let valuesToTrim = ["email", "password", "confirmPassword"];
    valuesToTrim.forEach((key) => {
      formattedValues[key] = trimOffTheTop(formattedValues[key]);
    });
    let user = await axios.patch("/api/auth/reset-password", formattedValues);
    return user;
  };

  useEffect(() => {
    const reset = async () => {
      const res = await axios.get(
        `/api/auth/forgot-password?resetPasswordToken=${token}`
      );
      res.data.message === "Password reset link has been verified"
        ? setTokenError(false)
        : setTokenError(true);
      setEmail(res.data.email);
    };
    reset();
  }, [token]);

  return (
    <section className="">
      <div className="">
        <div className="row justify-content-center">
          <div
            className="col-lg-6 col-md-4"
            //fixme
            style={{
              backgroundImage: `url("${
                process.env.PUBLIC_URL + "/assets/covers/judges_stripe.jpg"
              }")`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              minHeight: "100vh",
              display: "always",
            }}
          ></div>
          <div className="col-lg-6 col-md-8 text-center">
            <h1 className="text-dark pt-10">Reset Password</h1>

            <Breadcrumbs />
            {!tokenError && email.length > 0 ? (
              <div className="ml-20 mr-20">
                <div className="register-form">
                  <Formik
                    enableReinitialize={true}
                    initialValues={initForm}
                    validationSchema={resetSchema}
                    onSubmit={async (values) => {
                      try {
                        resetPassword(values)
                          .then((user) => {
                            console.log(user);
                            if (user.status === 200) {
                              navigate("/login");
                            }
                          })
                          .catch((err) => {
                            if (err.response.status === 401) {
                              setError("Invalid credentials");
                            } else {
                              console.log(err.response.data);
                              console.log(err.response.status);
                              setError(
                                "ERROR: Please Reach Out To Your Account Admin For Help"
                              );
                            }
                          });
                      } catch {
                        console.log("forgot password error");
                      }
                    }}
                  >
                    {({ touched, errors }) => (
                      <Form className="contact-form">
                        <div className="form-grp">
                          <div className="row">
                            <div className="col-lg-12">
                              <PasswordField
                                type="password"
                                name="password"
                                id="password"
                                label="New Password"
                                className="rounded"
                                placeholder="New Password"
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-12">
                              <PasswordField
                                type="password"
                                name="confirmPassword"
                                id="confirmPassword"
                                label="Confirm Password"
                                className="rounded"
                                placeholder="Confirm Password"
                              />
                            </div>
                          </div>
                        </div>

                        {error !== "" && (
                          <div>
                            <small className="text-danger">{error}</small>
                          </div>
                        )}
                        <button type="submit" className="btn btn-primary">
                          Reset
                        </button>
                        <a href="/login" className="pl-20">
                          Cancel
                        </a>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            ) : (
              <Alert variant="danger">
                Link is expired/invalid. Please try again
              </Alert>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}

export default ResetPasswordArea;
