/**
 * Login Area
 * @desc The contents of the login page
 * @component
 * @public
 *
 * @param None
 *
 * @todo
 * - Fix error handling
 *
 * @example
 * Note: Remember to wrap in a Layout
 *       component
 * <LoginArea />
 *
 * @author Asad Siddiqui
 * @created_on 2024-08-15
 * @modified_on 2024-08-15
 */
import axios from "axios";
import { Field, Form, Formik } from "formik";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import Breadcrumbs from "../../../Components/Breadcrumbs/Breadcrumbs";
import { authActions } from "../../../store/auth/authSlice";

function LoginArea() {
  const navigate = useNavigate();
  const events = useSelector((state) => state.events);
  const [showPassword, setShowPassword] = useState("");
  const [error, setError] = useState("");
  const initLoginValues = {
    email: "",
    password: "",
  };

  const dispatch = useDispatch();

  const loginSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email address")
      .required("An email is required"),
    password: Yup.string().required("A password is required"),
  });

  const login = async (values) => {
    let user = await axios.post("/api/auth/login", values);
    return user;
  };

  return (
    <section className="">
      <div className="">
        <div className="row justify-content-center">
          <div
            className="col-lg-6 col-md-4"
            //fixme
            style={{
              backgroundImage: `url("${
                process.env.PUBLIC_URL + "/assets/covers/judges_stripe.jpg"
              }")`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              minHeight: "100vh",
              display: "always",
            }}
          ></div>
          <div className="col-lg-6 col-md-8 text-center">
            <h1 className="text-dark pt-10">Login</h1>

            <Breadcrumbs />

            <div className="ml-20 mr-20">
              <div className="register-form">
                <Formik
                  enableReinitialize={true}
                  initialValues={initLoginValues}
                  validationSchema={loginSchema}
                  onSubmit={async (values) => {
                    try {
                      login(values)
                        .then((user) => {
                          dispatch(authActions.handleLogin(user.data));
                          if (
                            events &&
                            Object.keys(events.event).length > 0 &&
                            user.data.teams &&
                            user.data.teams.length === 0
                          ) {
                            navigate("/team-management/create");
                          } else if (
                            events &&
                            Object.keys(events.event).length > 0
                          ) {
                            navigate("/events");
                          } else {
                            navigate("/");
                          }
                        })
                        .catch((err) => {
                          if (err.response.status === 401) {
                            setError("Invalid credentials");
                          } else {
                            console.log(err.response.data);
                            console.log(err.response.status);
                            setError(
                              "ERROR: Please Reach Out To Your Account Admin For Help"
                            );
                          }
                        });
                    } catch {
                      console.log("login error");
                    }
                  }}
                >
                  {({ touched, errors }) => (
                    <Form className="contact-form">
                      <div className="form-grp">
                        <Field
                          type="email"
                          name="email"
                          id="email"
                          placeholder="Your email..."
                          className="form-control  rounded"
                        />
                        {errors["email"] && touched["email"] && (
                          <small className="text-danger">
                            {errors["email"].toString()}
                          </small>
                        )}
                      </div>
                      <div className="form-grp">
                        <div className="input-group">
                          <Field
                            type={showPassword ? "text" : "password"}
                            name="password"
                            id="password"
                            className="form-control rounded"
                            placeholder="Password"
                          />
                          <span
                            className="password-toggle eye-icon"
                            onClick={() => setShowPassword(!showPassword)}
                          >
                            <i
                              className={`fa ${
                                showPassword ? "fa-eye-slash" : "fa-eye"
                              }`}
                            ></i>
                          </span>
                        </div>
                        {errors["password"] && touched["password"] && (
                          <small className="text-danger">
                            {errors["password"].toString()}
                          </small>
                        )}
                        {error !== "" && (
                          <div>
                            <small className="text-danger">{error}</small>
                          </div>
                        )}
                      </div>
                      <div className="password-forgot mb-50">
                        <a
                          className="text-theme text-hover-muted"
                          href="/forgot-password"
                        >
                          Forgot your password?
                        </a>
                      </div>
                      <button type="submit" className="btn btn-primary">
                        Login
                      </button>
                      <a href="/signup" className="pl-20">
                        Create Account
                      </a>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default LoginArea;
