import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { IoLogIn, IoPersonCircle } from "react-icons/io5";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const NavMenuCollapse = () => {
  const auth = useSelector((state) => state.auth);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [eventsDropdownOpen, setEventsDropdownOpen] = useState(false);
  const navigate = useNavigate();

  const handleMouseEnter = () => setDropdownOpen(true);
  const handleMouseLeave = () => setDropdownOpen(false);


  const handleEventsMouseEnter = () => setEventsDropdownOpen(true);
  const handleEventsMouseLeave = () => setEventsDropdownOpen(false);

  const handleButtonClick = () => {
    navigate("/seasons");
  };

  const handleEventsButtonClick = () => {
    navigate("/events");
  };

  return (
    <Navbar collapseOnSelect expand="lg" sticky="top" className="bg-white">
      <Container>
        <Navbar.Brand href="/">
          <img src="/assets/img/logo/stripe_logo_sm2.png" alt="stripe-logo" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="m-auto">
            {!auth.isLoggedIn ? (
              <>
                {" "}
                <Nav.Link className="main-nav-item" href="/">
                  Home
                </Nav.Link>
                <Nav.Link
                  className="main-nav-item d-lg-none d-block"
                  href="/seasons"
                >
                  Season
                </Nav.Link>
                <NavDropdown
                  title={<span className="text-secondary">Season</span>}
                  id="season-dropdown"
                  className="season-button d-lg-block d-none"
                  drop="down"
                  href="/seasons"
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  onClick={handleButtonClick}
                  show={dropdownOpen}
                >
                  <NavDropdown.Item href="/seasons#current-season">
                    Season Theme
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/seasons#streams">
                    Streams
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/seasons#leagues">
                    League System
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/seasons#coach-rules">
                    Rules & Materials
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/seasons#season-challenges">
                    Season Challenges
                  </NavDropdown.Item>
                </NavDropdown>

                <Nav.Link
                  className="main-nav-item d-lg-none d-block"
                  href="/events"
                >
                  Events
                </Nav.Link>
                <NavDropdown
                  title={<span className="text-secondary">Events</span>}
                  id="events-dropdown"
                  className="events-button d-lg-block d-none"
                  drop="down"
                  href="/events"
                  onMouseEnter={handleEventsMouseEnter}
                  onMouseLeave={handleEventsMouseLeave}
                  onClick={handleEventsButtonClick}
                  show={eventsDropdownOpen}
                >
                  <NavDropdown.Item href="/events">
                    Register for Events
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/event-score">
                    Leaderboard
                  </NavDropdown.Item>
                </NavDropdown>
                <Nav.Link className="main-nav-item" href="/about">
                  About
                </Nav.Link>
                <Nav.Link className="main-nav-item" href="/sponsors">
                  Sponsors
                </Nav.Link>
              </>
            ) : auth.organizations[0].roleId === 1 ||
              auth.organizations[0].roleId === 2 ||
              auth.organizations[0].roleId === 3 ? (
              <>
                <Nav.Link className="main-nav-item" href="/dashboard">
                  My Dashboard
                </Nav.Link>
                <Nav.Link
                  className="main-nav-item d-lg-none d-block"
                  href="/seasons"
                >
                  Season
                </Nav.Link>
                <NavDropdown
                  title={<span className="text-secondary">Season</span>}
                  id="season-dropdown"
                  className="season-button d-lg-block d-none"
                  drop="down"
                  href="/seasons"
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  onClick={handleButtonClick}
                  show={dropdownOpen}
                >
                  <NavDropdown.Item href="/seasons#current-season">
                    Season Theme
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/seasons#leagues">
                    League System
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/seasons#coach-rules">
                    Rules & Materials
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/seasons#season-challenges">
                    Season Challenges
                  </NavDropdown.Item>
                </NavDropdown>

                <Nav.Link
                  className="main-nav-item d-lg-none d-block"
                  href="/events"
                >
                  Events
                </Nav.Link>
                <NavDropdown
                  title={<span className="text-secondary">Events</span>}
                  id="events-dropdown"
                  className="events-button d-lg-block d-none"
                  drop="down"
                  href="/events"
                  onMouseEnter={handleEventsMouseEnter}
                  onMouseLeave={handleEventsMouseLeave}
                  onClick={handleEventsButtonClick}
                  show={eventsDropdownOpen}
                >
                  <NavDropdown.Item href="/events">
                    Register for Events
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/event-score">
                    Leaderboard
                  </NavDropdown.Item>
                  {(auth?.organizations[0].roleId === 1 ||
                    auth?.organizations[0].roleId === 2) && (
                    <NavDropdown.Item href="/event-score-entry">
                      Score Maintenance 
                    </NavDropdown.Item>
                  )}
                </NavDropdown>
                <Nav.Link className="main-nav-item" href="/teams">
                  Teams
                </Nav.Link>
              </>
            ) : (
              <>
                <Nav.Link className="main-nav-item" href="/organizer-dashboard">
                  My Dashboard
                </Nav.Link>
                <Nav.Link
                  className="main-nav-item d-lg-none d-block"
                  href="/seasons"
                >
                  Season
                </Nav.Link>
                <NavDropdown
                  title={<span className="text-secondary">Season</span>}
                  id="season-dropdown"
                  className="season-button d-lg-block d-none"
                  drop="down"
                  href="/seasons"
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  onClick={handleButtonClick}
                  show={dropdownOpen}
                >
                  <NavDropdown.Item href="/seasons#current-season">
                    Season Theme
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/seasons#streams">
                    Streams
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/seasons#leagues">
                    League System
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/seasons#coach-rules">
                    Rules & Materials
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/seasons#season-challenges">
                    Season Challenges
                  </NavDropdown.Item>
                </NavDropdown>
              </>
            )}
          </Nav>

          {!auth.isLoggedIn ? (
            <Nav>
              <Nav.Link
                className="main-nav-action-item border-end border-dark"
                href="login"
              >
                <IoLogIn size={"1.5em"} />
                Login
              </Nav.Link>

              <Nav.Link className="main-nav-action-item" href="signup">
                Sign Up
              </Nav.Link>
            </Nav>
          ) : auth.organizations[0].roleId === 1 ||
            auth.organizations[0].roleId === 2 ||
            auth.organizations[0].roleId === 3 ? (
            <Nav>
              <NavDropdown
                title={
                  <>
                    <span className="border-left pl-20">
                      {"Hi, " + auth.user.firstName}
                    </span>
                    <IoPersonCircle size={"1.5em"} />
                  </>
                }
                id="collapsible-nav-dropdown"
                align="end"
              >
                <NavDropdown.Item
                  className="sub-main-nav-item"
                  href="/edit-account"
                >
                  Edit Account
                </NavDropdown.Item>
                <div className="dropdown-divider"></div>
                <NavDropdown.Item className="sub-main-nav-item" href="/logout">
                  Sign Out
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
          ) : (
            <Nav>
              {/*fixme*/}
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <button className="btn btn-secondary">Add Events</button>
              </div>

              <NavDropdown
                title={
                  <>
                    <span className="border-left pl-20">
                      {"Hi, " + auth.user.firstName}
                    </span>
                    <IoPersonCircle size={"1.5em"} />
                  </>
                }
                id="collapsible-nav-dropdown"
                align="end"
              >
                <NavDropdown.Item
                  className="sub-main-nav-item"
                  href="/edit-account"
                >
                  My Account
                </NavDropdown.Item>
                <div className="dropdown-divider"></div>
                <NavDropdown.Item className="sub-main-nav-item" href="/logout">
                  Sign Out
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
          )}
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavMenuCollapse;
