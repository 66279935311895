import React, { useEffect, useState } from "react";
import { FaFacebook, FaYoutube } from "react-icons/fa";
import { RiInstagramFill } from "react-icons/ri";
import { Link } from "react-router-dom";
import { animateScroll } from "react-scroll";

const FooterOne = ({ spaceTopClass, spaceBottomClass }) => {
  /*
    Constants and UseStates
  */
  const [scroll, setScroll] = useState(0);
  const [top, setTop] = useState(0);

  /*
  Functions
  */

  const scrollToTop = () => {
    animateScroll.scrollToTop();
  };

  const handleScroll = () => {
    setScroll(window.scrollY);
  };

  /*
    UseEffects
  */
  useEffect(() => {
    setTop(100);
    // ScrollToHashWithOffset();
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <footer
      className={`footer-area bg-dark ${spaceTopClass ? spaceTopClass : ""} ${
        spaceBottomClass ? spaceBottomClass : ""
      }`}
    >
      <div className={`container`}>
        <div className="row mr-50">
          <div className={`col-xl-3 col-sm-4 mb-30 ml-30 mr-30`}>
            <img
              src="/assets/img/logo/stripe_dark_logo2.png"
              alt="stripe-logo"
            />
            <div className="row">
              <div className="col-sm-3 ml-10">
                <a
                  className="text-white fs-36"
                  rel="noreferrer"
                  href="https://www.facebook.com/people/Stripe-Competition/100091801277279/"
                  target="_blank"
                >
                  <FaFacebook />
                </a>
              </div>
              <div className="col-sm-3 ml-10">
                <a
                  className="text-white fs-36"
                  href="https://www.instagram.com/stripecompetition/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <RiInstagramFill />
                </a>
              </div>
              <div className="col-sm-3 ml-10">
                <a
                  className="text-white fs-36"
                  href="https://www.youtube.com/@STRIPECompetition"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaYoutube />
                </a>
              </div>
            </div>
          </div>
          <div className={`col-xl-2 col-sm-4`}>
            <div className="footer-widget mb-30 ml-30">
              <div className="footer-title">
                <h3>ABOUT US</h3>
              </div>
              <div className="footer-list">
                <ul className="ul-display">
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/about"}>About</Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/sponsors"}>
                      Sponsors
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/FAQ"}>FAQ</Link>
                  </li>
                  <li>
                    {/* add page */}
                    <Link to={process.env.PUBLIC_URL + "/termsAndCopyright"}>Terms and Copyright</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className={`col-xl-2 col-sm-4`}>
            <div className="footer-widget mb-30 ml-30">
              <div className="footer-title">
                <h3>SEASON</h3>
              </div>
              <div className="footer-list">
                <ul className="ul-display">
                  <li>
                    <Link
                      to={process.env.PUBLIC_URL + "/seasons#current-season"}
                    >
                      Season Theme
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/seasons#streams"}>
                      Streams
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/seasons#leagues"}>
                      League System
                    </Link>
                  </li>

                  <li>
                    <Link to={process.env.PUBLIC_URL + "/seasons#coach-rules"}>
                      Rules and Materials
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={process.env.PUBLIC_URL + "/seasons#season-challenges"}
                    >
                      Season Challenges
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className={`col-xl-2 col-sm-4`}>
            <div className="footer-widget mb-30 ml-30">
              <div className="footer-title">
                <h3>EVENTS</h3>
              </div>
              <div className="footer-list">
                <ul className="ul-display">
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/events?streamId=1"}>
                      Programming
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/events?streamId=2"}>
                      Robotics
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/events?streamId=3"}>
                      Innovation
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="row mr-50">
          <div className={`col-xl-3 col-sm-4 mb-30 ml-30 mr-30`}></div>
          <div className={`col-xl-3 col-sm-4 mb-30 ml-30 mr-30`}>
            <Link
              to={process.env.PUBLIC_URL + "/about#contact-us"}
              className="btn btn-secondary"
            >
              Contact us
            </Link>
          </div>
        </div>
      </div>
      <button
        className={`scroll-top ${scroll > top ? "show" : ""}`}
        onClick={() => scrollToTop()}
      >
        <i className="fa fa-angle-double-up"></i>
      </button>
    </footer>
  );
};

export default FooterOne;
