/**
 * Forgot Password Area
 * @desc The contents of the forgot passwrd page
 * @component
 * @public
 *
 * @param None
 *
 * @todo
 * - Fix error handling
 *
 * @example
 * Note: Remember to wrap in a Layout
 *       component
 * <ForgotPasswordArea />
 *
 * @author Asad Siddiqui
 * @created_on 2024-12-10
 * @modified_on 2024-12-10
 */
import axios from "axios";
import { Field, Form, Formik } from "formik";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import Breadcrumbs from "../../../Components/Breadcrumbs/Breadcrumbs";

function ForgotPasswordArea() {
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const initLoginValues = {
    email: "",
  };

  const loginSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email address")
      .required("An email is required"),
  });

  const forgotPassword = async (values) => {
    setError("");
    let user = await axios.post("/api/auth/forgot-password", values);
    return user;
  };

  return (
    <section className="">
      <div className="">
        <div className="row justify-content-center">
          <div
            className="col-lg-6 col-md-4"
            //fixme
            style={{
              backgroundImage: `url("${
                process.env.PUBLIC_URL + "/assets/covers/judges_stripe.jpg"
              }")`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              minHeight: "100vh",
              display: "always",
            }}
          ></div>
          <div className="col-lg-6 col-md-8 text-center">
            <h1 className="text-dark pt-10">Forgot Password</h1>

            <Breadcrumbs />

            <div className="ml-20 mr-20">
              <div className="register-form">
                <Formik
                  enableReinitialize={true}
                  initialValues={initLoginValues}
                  validationSchema={loginSchema}
                  onSubmit={async (values) => {
                    try {
                      forgotPassword(values)
                        .then((user) => {
                          console.log(user);
                          if (user.status === 200) {
                            navigate("/login");
                          }
                        })
                        .catch((err) => {
                          if (err.response.status === 400) {
                            setError("Invalid credentials");
                          } else {
                            console.log(err.response.data);
                            console.log(err.response.status);
                            setError(
                              "ERROR: Please Reach Out To Your Account Admin For Help"
                            );
                          }
                        });
                    } catch {
                      console.log("forgot password error");
                    }
                  }}
                >
                  {({ touched, errors }) => (
                    <Form className="contact-form">
                      <div className="form-grp">
                        <Field
                          type="email"
                          name="email"
                          id="email"
                          placeholder="Your email..."
                          className="form-control  rounded"
                        />
                        {errors["email"] && touched["email"] && (
                          <small className="text-danger">
                            {errors["email"].toString()}
                          </small>
                        )}
                      </div>

                      {error !== "" && (
                        <div>
                          <small className="text-danger">{error}</small>
                        </div>
                      )}
                      <button type="submit" className="btn btn-primary">
                        Submit
                      </button>
                      <a href="/login" className="pl-20">
                        Cancel
                      </a>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ForgotPasswordArea;
