import React from "react";
import StreamsWithDetails from "../../../Widgets/StreamsWithDetails";
import FunFactOne from "./Fact";
import HeroSingle from "./HeroSingle";

export const WelcomeSection = () => {
  return (
    <div className="welcome-section">
      <div className="pt-5 pb-6">
        <div className="row">
          <div className="col-lg-6 col-sm-12">
            <div className="grey-box-wrapper">
              <div className="blue-border">
                <img
                  src={
                    process.env.PUBLIC_URL + "/assets/covers/border-corner1.png"
                  }
                  alt="Decorative"
                  className="blue-border-image"
                />
              </div>

              <div className="col grey-box">
                <h3>
                  Welcome to <strong>STRIPE!</strong>
                </h3>
                <p>
                  The STRIPE Competition is a global platform where students
                  bring their STEM aspirations to life! Aimed at inspiring and
                  educating, STRIPE stands for science, technology, robotics,
                  innovation, programming, and engineering and leverages the
                  excitement of competition to encourage students to delve in
                  unprecedented ways.
                  <br />
                  <br />
                  Whether you're an educator just starting with robotics
                  competitions or a seasoned STEM enthusiast, STRIPE offers
                  opportunities for everyone. Join us in crafting memorable
                  learning experiences that spark curiosity and empower the
                  innovators of tomorrow.
                  <br />
                  <br />
                  Sign up for an event today to earn your stripes!
                </p>
              </div>
              <div className="d-sm-block yellow-border">
                <img
                  src={
                    process.env.PUBLIC_URL + "/assets/covers/border-corner2.png"
                  }
                  alt="Decorative"
                />
              </div>
            </div>
          </div>

          <div className="offset-lg-1 col-lg-5 col-sm-12">
            <div className="video-frame">
              <iframe
                width="100%"
                height="300"
                src="https://www.youtube.com/embed/P-qaVEr-p5s?si=f71SlYr8GThxLo1X&rel=0"
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const EngagingStudents = () => {
  return (
    <div className="row align-items-center pb-20 mt-40 mb-40 bg-gray-2 pb-10 pt-20 rounded">
      <div className="col-lg-6 justify-content-center">
        <img
          className="w-p-80 h-p-80 rounded"
          src={
            process.env.PUBLIC_URL +
            "/assets/covers/engagingStudentsInStem1.jpeg"
          }
          alt="Students Working on Robot"
        />
      </div>
      <div className="col-lg-6">
        <div className="pb-10 pt-20  justify-content-start">
          <h2>Engaging Students in STEM</h2>
        </div>
        The primary goal of the STRIPE Competition is to assist students in
        developing their abilities in robotics, coding, and technology through
        enjoyable and stimulating challenges each season, while also providing
        them with practical experience in addressing real-world issues.
        <br />
        <br />
        STRIPE Competition aims to collaborate with teachers, coaches, parents,
        and mentors to equip students with the resources necessary for their
        success. Join us this season to earn your stripes!
      </div>
    </div>
  );
};

function MainPage() {
  return (
    <div>
      <HeroSingle
        img={"/assets/covers/robot_intro.jfif"}
        title={"STRIPE Competition"}
        subtitle={"EARN YOUR STRIPES".toUpperCase()}
        link={"/events"}
        btnText={"Find An Event"}
      ></HeroSingle>

      <div className="container-lg ">
        <WelcomeSection />
        <FunFactOne
          spaceTopClass="pt-35"
          spaceBottomClass="pb-20"
          bgClass="bg-fact-area"
        />

        <EngagingStudents />
        <StreamsWithDetails />
      </div>
    </div>
  );
}

export default MainPage;
