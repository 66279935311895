import React, { useState } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import LayoutTwo from "../../Components/Layout/LayoutTwo";
import RoleOptions from "./RoleOptions";
import SignUpArea from "./Widgets/SignUpArea";

function SignUpPage() {
  const [role, setRole] = useState(-1);
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>Signup | Stripe Competition</title>
          <meta
            name="description"
            content="Coding, Robotics, and Technology Competition."
          />
        </Helmet>
        <LayoutTwo>
          {role === -1 ? (
            <RoleOptions role={role} setRole={setRole} />
          ) : (
            <SignUpArea role={role} />
          )}
        </LayoutTwo>
      </HelmetProvider>
    </>
  );
}

export default SignUpPage;
