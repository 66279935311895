import React from "react";
import ArrowHeader from "../../../Widgets/ArrowHeader.js";

function EthicsBar() {
  return (
    <div className="ml-10 mr-10 bg-gray mt-40">
      <div className="pt-10">
        <ArrowHeader title="Ethics" />
      </div>

      <div className="row">
        <div className="offset-1 col-10">
          {" "}
          <p className="text-centered">
            At STRIPE Competition, we’re all about building awesome robots,
            cracking coding challenges — and even more, awesome people! That’s
            why we live by three key ethics: Respect, Collaboration, and
            Perseverance. Think of these as the secret sauce to leveling up both
            your team and you.
          </p>
        </div>
      </div>
      <div className="row pt-20 pb-20 justify-content-center">
        <div className="col-lg-3 col-sm-12">
          {" "}
          <div className="flip-card">
            <div className="flip-card-inner">
              <div className="flip-card-front">
                <img
                  src={process.env.PUBLIC_URL + "/assets/covers/respect.png"}
                  className="ethics-img"
                  alt="Kids cheering"
                />
              </div>
              <div className="flip-card-back">
                <p className="flip-card-text">
                  {" "}
                  Respect is like the golden rule—treat everyone (yes, even that
                  rival team) with kindness and fairness. High fives all around
                  for good ideas, encouraging words when things get tricky, and
                  always remembering that everyone brings something unique to
                  the table. Plus, it's way more fun to work with people who
                  feel valued and appreciated, right?
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-sm-12">
          <div className="flip-card">
            <div className="flip-card-inner">
              <div className="flip-card-front">
                <img
                  src={process.env.PUBLIC_URL + "/assets/covers/collab.png"}
                  className="ethics-img"
                  alt="Kids cheering"
                />
              </div>
              <div className="flip-card-back">
                <p className="flip-card-text">
                  {" "}
                  Two (or three) brains are better than one! Collaboration is
                  where the magic happens. In STRIPE, teamwork is
                  everything—sharing ideas, dividing tasks, and building on each
                  other's strengths to create something epic. Need a robot that
                  can dance and dunk? Teamwork makes the dream work! And when
                  everyone pitches in, the win feels even sweeter.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-sm-12">
          <div className="flip-card">
            <div className="flip-card-inner">
              <div className="flip-card-front">
                <img
                  src={
                    process.env.PUBLIC_URL + "/assets/covers/perseverance.png"
                  }
                  className="ethics-img"
                  alt="Kids cheering"
                />
              </div>
              <div className="flip-card-back">
                <p className="flip-card-text">
                  {" "}
                  Sometimes code crashes. Sometimes robots break. Sometimes
                  things just don't go as planned. That's okay—because in
                  STRIPE, we know that success is built on a mountain of "oops"
                  moments. Perseverance is about rolling up your sleeves,
                  learning from mistakes, and trying again (and again). Every
                  stumble is a step closer to something amazing.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EthicsBar;
