import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import LayoutTwo from "../../Components/Layout/LayoutTwo";
import ResetPasswordArea from "./Widgets/ResetPasswordArea";

function ResetPasswordPage() {
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>Reset Password | Stripe Competition</title>
          <meta
            name="description"
            content="Coding, Robotics, and Technology Competition."
          />
        </Helmet>
        <LayoutTwo>
          <ResetPasswordArea />
        </LayoutTwo>
      </HelmetProvider>
    </>
  );
}

export default ResetPasswordPage;
