import axios from "axios";
import { ErrorMessage, Field, Formik } from "formik";
import React, { useRef, useState } from "react";
import { Alert } from "react-bootstrap";
import * as Yup from "yup";
import { getFormattedNumber, trimOffTheTop } from "../../../helpers/formatter";
import { InputWithLimit } from "../../../Widgets/FormHelpers/InputWithLimit";

const initialValues = {
  full_name: "",
  email: "",
  mobile: "",
  city: "",
  interest: "",
  referral: "Choose...",
  referral_other: "",
  comments: "",
  policy_and_consent: false,
};

const ContactForm = () => {
  var phoneRegExp = /^\([0-9]{3}\) - [0-9]{3} - [0-9]{4}/;
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const myRef = useRef(null);
  const schema = Yup.object({
    full_name: Yup.string()
      .required("Full Name is required")
      .test("length-test", "Full Name is required", (value, context) => {
        return value && value.length !== 1;
      }),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required")
      .test(
        "valid-email",
        "Sorry, only letters (a-z), numbers (0-9), apostrophe (.), hypen (-), and underscores (_) are allowed",
        (value, context) => {
          return (
            value &&
            value.split("@").length > 0 &&
            value.split("@")[0].match(/[(a-z)(A-Z)(0-9)(.)(_)(\-)]*/g)[0] ===
              value.split("@")[0]
          );
        }
      ),
    mobile: Yup.string()
      .matches(phoneRegExp, "Please enter a valid phone number")
      .min(18, "Phone Number must be exactly 10 digits")
      .required("Phone Number is required")
      .test(
        "valid-number",
        "Sorry, only numbers (0-9) are allowed",
        (value, context) => {
          return (
            value &&
            value.split("@").length > 0 &&
            value.split("@")[0].match(/[(0-9)(\-)(( ))]*/g)[0] ===
              value.split("@")[0]
          );
        }
      ),
    city: Yup.string()
      .required("City is required")
      .test("length-test", "City is required", (value, context) => {
        return value && value.length !== 1;
      }),
    interest: Yup.string()
      .oneOf(
        ["Registration", "Organizing an Event", "Other"],
        "Area of Interest is required"
      )
      .required("Required"),
    referral: Yup.string()
      .oneOf(
        [
          "Google Search",
          "Returning Student",
          "Friends and Family",
          "Social Media",
          "Drive Thru",
          "Company Email",
          "Other",
        ],
        "How did you hear about us is required"
      )
      .required("Required"),
    comments: Yup.string(),
    policy_and_consent: Yup.boolean()
      .required("Required")
      .oneOf([true], "You must accept the terms and conditions."),
  });

  const executeScroll = () => {
    myRef?.current?.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  const onValidated = async ({ values }) => {
    executeScroll();
  };

  return (
    <div>

      {showSuccessAlert && (
        <Alert
          variant="success"
          onClose={() => setShowSuccessAlert(false)}
          dismissible
        >
          <Alert.Heading>Thank you for submitting your request!</Alert.Heading>
          <p>
            One of our counsellors will be reaching out to you by email or a
            phone call from us soon.
          </p>
        </Alert>
      )}
      {showErrorAlert && (
        <Alert
          variant="danger"
          onClose={() => setShowErrorAlert(false)}
          dismissible
        >
          <Alert.Heading>Woops, looks like something went wrong</Alert.Heading>
          <p>Please check your input and try again.</p>
        </Alert>
      )}
      <Formik
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={(values, { resetForm }) => {
          setShowSuccessAlert(true);
          setShowErrorAlert(false);
          let formattedValues = { ...values };
          let valuesToTrim = [
            "full_name",
            "email",
            "mobile",
            "city",
            "interest",
            "referral",
            "comments",
          ];

          valuesToTrim.forEach((key) => {
            formattedValues[key] = trimOffTheTop(formattedValues[key]);
          });
          axios
            .post("/api/contact_us", formattedValues)
            .then((res) => {
              if (res.status === 200) {
                setShowSuccessAlert(1);
                resetForm();
              } else {
                setShowErrorAlert(1);
              }
            })
            .catch((e) => {
              console.log(e);
              setShowErrorAlert(1);
            });
          onValidated({
            formattedValues,
          });
        }}
      >
        {(formik) => (
          <form onSubmit={formik.handleSubmit} className="contact-form-style">
        
            <div className="row">
              <div className="col-lg-6 form-group">
                <InputWithLimit
                  label="Name"
                  name={`full_name`}
                  id="full_name"
                  type="text"
                  touched={formik.touched.full_name}
                  errors={formik.errors.full_name}
                  maxLength={225}
                  displaylimit={"false"}
                  {...formik.getFieldProps("full_name")}
                />
              </div>

              <div className="col-lg-6 form-group">
                <InputWithLimit
                  label="Email"
                  name="email"
                  id="email"
                  type="email"
                  touched={formik.touched.email}
                  errors={formik.errors.email}
                  maxLength={100}
                  displaylimit={"false"}
                  {...formik.getFieldProps("email")}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 form-group">
                <InputWithLimit
                  label="Phone"
                  name="mobile"
                  id="mobile"
                  type="text"
                  touched={formik.touched.mobile}
                  errors={formik.errors.mobile}
                  {...formik.getFieldProps("mobile")}
                  onChange={(e) => {
                    let phoneNumber = getFormattedNumber(e.target.value);
                    formik.setFieldValue("mobile", phoneNumber);
                  }}
                  maxLength={18}
                  displaylimit={"false"}
                />
              </div>

              <div className="col-lg-6 form-group">
                <InputWithLimit
                  label="City"
                  name="city"
                  id="city"
                  type="text"
                  maxLength={50}
                  displaylimit={"false"}
                  touched={formik.touched.city}
                  errors={formik.errors.city}
                  {...formik.getFieldProps("city")}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 form-group">
                <label htmlFor="interest">Area of Interest</label>

                <select
                  name="interest"
                  id="interest"
                  className={
                    "form-control" +
                    (formik.errors.interest && formik.touched.interest
                      ? " is-invalid"
                      : "")
                  }
                  {...formik.getFieldProps("interest")}
                >
                  <option>Choose...</option>
                  {["Registration", "Organizing an Event", "Other"].map((i) => (
                    <option key={i}>{i}</option>
                  ))}
                </select>
                <ErrorMessage
                  name={`interest`}
                  component="div"
                  className="invalid-feedback"
                />
              </div>

              <div className="col-lg-6 form-group">
                <label htmlFor="referral">How did you hear about us?</label>
                <select
                  name="referral"
                  id="referral"
                  className={
                    "form-control" +
                    (formik.errors.referral && formik.touched.referral
                      ? " is-invalid"
                      : "")
                  }
                  {...formik.getFieldProps("referral")}
                >
                  <option>Choose...</option>
                  <option>Google Search</option>
                  <option>Social Media</option>
                  <option>Friends and Family</option>
                  <option>Drive Thru</option>
                  <option>Company Email</option>
                  <option>Returning Student</option>
                  <option>Other</option>
                </select>
                <ErrorMessage
                  name={`referral`}
                  component="div"
                  className="invalid-feedback"
                />

                {formik.values.referral === "Other" && (
                  <fieldset className="form-group">
                    <InputWithLimit
                      label="Please Specify"
                      name="referral_other"
                      id="referral_other"
                      type="text"
                      touched={formik.touched.referral_other}
                      errors={formik.errors.referral_other}
                      maxLength={100}
                      displaylimit={true}
                      {...formik.getFieldProps("referral_other")}
                    />
                  </fieldset>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 form-group">
                <InputWithLimit
                  label="Comments"
                  name="comments"
                  id="comments"
                  type="textarea"
                  maxLength={225}
                  displaylimit={true}
                  touched={formik.touched.comments}
                  errors={formik.errors.comments}
                  rows="6"
                  {...formik.getFieldProps("comments")}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12  form-group">
                <label className="mb-0">
                  {/*fixme*/}
                  <Field
                    type="checkbox"
                    name="policy_and_consent"
                    style={{
                      height: "15px",
                      width: "15px",
                      marginRight: "10px",
                      marginTop: "15px",
                      display: "inline-block",
                    }}
                  />
                  {`By submitting, you're agreeing that we can contact you by
                  email, phone, or text. We'll only use your info to contact you
                  about us.`}
                </label>
                {formik.errors.policy_and_consent &&
                  formik.touched.policy_and_consent && (
                    <div className="text-danger">
                      You must accept the terms and conditions.
                    </div>
                  )}
              </div>
            </div>
            <button type="submit">Submit</button>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default ContactForm;
