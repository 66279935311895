import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import LayoutTwo from "../../Components/Layout/LayoutTwo";
import LoginArea from "./Widgets/LoginArea";

function LoginPage() {
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>Login | Stripe Competition</title>
          <meta
            name="description"
            content="Coding, Robotics, and Technology Competition."
          />
        </Helmet>
        <LayoutTwo>
          <LoginArea />
        </LayoutTwo>
      </HelmetProvider>
    </>
  );
}

export default LoginPage;
