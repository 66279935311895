import React from "react";
import { Link } from "react-router-dom";

const HeroSingle = ({ img, title, subtitle, link, btnText }) => {
  return (
    <div
      className="single-slide bg-img"
      //fixme
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL + img})`,
      }}
    >
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-10 ml-auto mr-auto">
            <div className="single-slide__content">
              <h2 className="subtitle">{subtitle}</h2>
              <h4 className="title">{title}</h4>
              <Link
                className="btn btn-secondary btn-hover-secondary hero-btn"
                to={process.env.PUBLIC_URL + link}
              >
                <span>{btnText}</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSingle;
