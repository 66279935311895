import { lazy } from "react";

//  Eager Load Critical Pages
import Error404 from "./Pages/ErrorPages/Error404";
import MainLandingPage from "./Pages/HomePage/MainLandingPage";
import LoginPage from "./Pages/Login/LoginPage";
import SignUpPage from "./Pages/Login/SignUpPage";
import AboutPage from "./Pages/About/AboutPage";
import SeasonsPage from "./Pages/Seasons/SeasonsPage";
import ResetPasswordPage from "./Pages/Login/ResetPassword";
import ForgotPasswordPage from "./Pages/Login/ForgotPassword";

// Lazy-loaded heavy & less frequently visited components
const LogoutPage = lazy(() => import("./Pages/Logout/LogoutPage"));
const SponsorsPage = lazy(() => import("./Pages/Sponsors/SponsorsPage"));
const OrganizerDashLandingPage = lazy(() =>
  import("./OrganizerDash/OrganizerDashLandingPage")
);
const PaymentFailed = lazy(() => import("./Pages/Checkout/PaymentFailed"));
const PaymentSuccess = lazy(() => import("./Pages/Checkout/PaymentSuccess"));
const RegisterPage = lazy(() => import("./Pages/Checkout/RegisterPage"));
const EventsPage = lazy(() => import("./Pages/Events/EventsPage"));
const FaqPage = lazy(() => import("./Pages/FAQ/FaqPage"));
const EventSinglePage = lazy(() =>
  import("./Pages/IndividualEvent/EventSinglePage")
);
const EditProfilePage = lazy(() => import("./Pages/MyAccount/EditProfilePage"));
const OrganizerSignUpPage = lazy(() =>
  import("./Pages/Organizers/OrganizerSignUpPage")
);
const ScorePage = lazy(() => import("./Pages/Score/ScorePage"));
const ChallengeDetailsPage = lazy(() =>
  import("./Pages/Seasons/Widgets/SeasonChallenges")
);
const TeamManagementPage = lazy(() =>
  import("./TeamDash/Components/TeamManagement/TeamManagementPage")
);
const Invoice = lazy(() => import("./TeamDash/Components/TeamOrder/Invoice"));
const TeamOrderDetails = lazy(() =>
  import("./TeamDash/Components/TeamOrder/TeamOrderDetails")
);
const TeamsPage = lazy(() =>
  import("./TeamDash/Components/TeamsPage/TeamsPage")
);
const TeamDashLandingPage = lazy(() =>
  import("./TeamDash/Pages/TeamDashLandingPage")
);
const TeamMemberPolicyPage = lazy(() =>
  import("./TeamDash/Pages/TeamMemberPolicyPage")
);
const EventScoreBoardPage = lazy(() =>
  import("./Pages/LeaderBoard/EventScoreBoardPage")
);
const TermsAndCopyrightPage = lazy(() =>
  import("./Pages/TermsAndCopyright/TermsAndCopyrightPage")
);
const EventScoreEntryPage = lazy(() =>
  import("./Pages/ScoreEntry/EventScoreEntryPage")
);

// Define routes as an exportable function
export const getRoutes = (auth) => {
  const loggedInRoutes = [
    { path: "/dashboard", element: <TeamDashLandingPage /> },
    { path: "/organizer-dashboard", element: <OrganizerDashLandingPage /> },
    { path: "/teams", element: <TeamsPage /> },
    { path: "/teams/order-details", element: <TeamOrderDetails /> },
    { path: "/edit-account", element: <EditProfilePage /> },
    { path: "/invoice", element: <Invoice /> },
  ];

  let routes = [
    { path: "season-challenges", element: <ChallengeDetailsPage /> },
    { path: "/", element: <MainLandingPage /> },
    { path: "/2024season", element: <MainLandingPage /> },
    { path: "seasons", element: <SeasonsPage /> },
    { path: "about", element: <AboutPage /> },
    { path: "sponsors", element: <SponsorsPage /> },
    { path: "organizer-sign-up", element: <OrganizerSignUpPage /> },
    { path: "events", element: <EventsPage /> },
    { path: "event/:eventId", element: <EventSinglePage /> },
    { path: "event-score", element: <EventScoreBoardPage /> },
    { path: "event-score-entry", element: <EventScoreEntryPage /> },
    { path: "score", element: <ScorePage /> },
    { path: "register", element: <RegisterPage /> },
    { path: "/payment-success", element: <PaymentSuccess /> },
    { path: "/payment-cancel", element: <PaymentFailed /> },
    {
      path: "/team-management/:mode",
      element: auth.isLoggedIn ? <TeamManagementPage /> : <Error404 />,
    },
    { path: "/policy", element: <TeamMemberPolicyPage /> },
    {
      path: "login",
      element: auth.isLoggedIn ? <MainLandingPage /> : <LoginPage />,
    },
    {
      path: "forgot-password",
      element: auth.isLoggedIn ? <MainLandingPage /> : <ForgotPasswordPage />,
    },
    {
      path: "reset-password/:token",
      element: auth.isLoggedIn ? <MainLandingPage /> : <ResetPasswordPage />,
    },
    {
      path: "signup",
      element: auth.isLoggedIn ? <MainLandingPage /> : <SignUpPage />,
    },
    {
      path: "logout",
      element: auth.isLoggedIn ? <LogoutPage /> : <MainLandingPage />,
    },
    { path: "FAQ", element: <FaqPage /> },
    { path: "termsAndCopyright", element: <TermsAndCopyrightPage /> },
    { path: "*", element: <Error404 /> },
  ];

  if (auth.isLoggedIn) {
    routes = routes.concat(loggedInRoutes);
  }

  return routes;
};
