import React, { useState } from "react";
import { GiLaurelsTrophy, GiWhistle } from "react-icons/gi";

const RoleOptions = ({ role, setRole }) => {
  const [selectedRole, setSelectedRole] = useState(-1);
  const getRoleMessage = () => {
    switch (selectedRole) {
      case 2:
        return (
          <>
            <div className="row pt-20 justify-content-center text-center ">
              <h4>I am interested in hosting my own STRIPE events</h4>
            </div>
            <div className="row justify-content-center text-center ">
              <div className="col-lg-3">
                <a className="btn btn-primary mt-20" href="/organizer-sign-up">
                  {" "}
                  Request to be an Organizer 
                </a>
              </div>
            </div>
          </>
        );
      case 3:
        return (
          <>
            <div className="row pt-20 justify-content-center text-center ">
              <h4>Creating a coach account is for:</h4>
            </div>
            <div className="row justify-content-center text-center ">
              <ul className="ul-display">
                <li>
                  <p>
                    <b>An educator</b> at a school looking to sign up an
                    indivual or team of students
                  </p>
                  <p>
                    <b>A parent</b> looking to sign up an individual child or a
                    team of children
                  </p>
                </li>
              </ul>
            </div>
            <div className="row justify-content-center text-center ">
              <div className="col-lg-3">
                <button
                  className="btn btn-primary mt-20"
                  onClick={() => {
                    setRole(3);
                  }}
                >
                  {" "}
                  Create Account
                </button>
              </div>
            </div>
          </>
        );

      default:
        return <p></p>;
    }
  };
  return (
    <section className="container">
      <h2 className=" text-center"> I AM A...</h2>
      <div className="mt-50 mb-50">
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12">
            <div
              className={`hover-box ${
                selectedRole === 3 ? "active-hover-box" : ""
              } text-center position-relative`}
              onClick={() => {
                let newRole = selectedRole === 3 ? -1 : 3;
                setSelectedRole(newRole);
              }}
            >
              <div className="hover-img position-absolute"></div>
              <div className="hover-box-inner">
                <GiWhistle size={70} />
                <h4 className="text-capitalize fs-26 mb-0 mt-1">
                  Coach/Mentor
                </h4>
              </div>
            </div>
          </div>

          <div className="col-lg-6 col-md-6 col-sm-12">
            <div
              className={`hover-box ${
                selectedRole === 2 ? "active-hover-box" : ""
              } text-center position-relative`}
              onClick={() => {
                let newRole = selectedRole === 2 ? -1 : 2;
                setSelectedRole(newRole);
              }}
            >
              <div className="hover-img position-absolute"></div>
              <div className="hover-box-inner">
                <GiLaurelsTrophy size={70} />
                <h4 className="text-capitalize fs-26 mb-0 mt-1">Organizer</h4>
              </div>
            </div>
          </div>
        </div>

        {selectedRole && getRoleMessage()}
      </div>
    </section>
  );
};

export default RoleOptions;
